import { ApolloCache, Reference, StoreObject, useMutation, useQuery } from '@apollo/client'
import {
  Modal,
  StackDivider,
  useBreakpointValue,
  useColorModeValue as mode,
  useDisclosure,
  VStack
} from '@chaine/keychaine'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { DELETE_WORKSPACE_ACCESS_REQUEST } from '../../layouts/application/main-container/data/mutations'
import {
  GET_USER_WORKSPACE_ACCESS_REQUEST,
  IWorkspaceAccessRequest
} from '../../layouts/application/main-container/data/queries'
import { IWorkspace } from '../../types'
import { Toast } from '..'
import { WorkspaceAccessRequest } from './workspace-access-request'
import { WorkspaceAccessRequestFlowPopup } from './workspace-access-request-flow-popup'

/**
 * WorkspaceAccessRequests component displays all active workspace access request to the user and provides the option to accept or decline them.
 */
export const WorkspaceAccessRequests = React.memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenVerificationFlow,
    onOpen: onOpenVerificationFlow,
    onClose: onCloseVerificationFlow
  } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false })
  const workspaceIDRef = useRef<string>('')
  const { showToast } = Toast()

  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace | null>(null)

  /** Fetches active workspace access requests for the current user */
  const { data } = useQuery<{ workspaceAccessRequests: IWorkspaceAccessRequest[] }>(GET_USER_WORKSPACE_ACCESS_REQUEST)

  /** Mutation delete access request */
  const [deleteAccessRequest, { loading: deleted }] = useMutation(DELETE_WORKSPACE_ACCESS_REQUEST, {
    onCompleted: () => {},
    onError: () => {
      showToast({ status: 'error', title: 'An error occurred while deleting the access request.' })
      onClose()
    },
    update(cache) {
      modifyCache(cache)
    }
  })

  /** Handler to accept an access request and open the verification flow */
  const handleAcceptAccessRequest = useCallback(
    (workspace: IWorkspace) => {
      setSelectedWorkspace(workspace)
      if (workspace.id) {
        workspaceIDRef.current = workspace.id
        onOpenVerificationFlow()
      }
    },
    [onOpenVerificationFlow]
  )

  /** Handler to delete an access request */
  const handleDeleteAccessRequest = useCallback(
    (workspaceID: string) => {
      workspaceIDRef.current = workspaceID
      deleteAccessRequest({ variables: { workspaceID: workspaceID } })
    },
    [deleteAccessRequest]
  )

  /** Updates the Apollo cache after an access request is deleted */
  const modifyCache = useCallback((cache: ApolloCache<unknown>) => {
    const workspaceID = workspaceIDRef.current
    cache.modify({
      fields: {
        workspaceAccessRequests(existingInvitations, { readField }) {
          return existingInvitations.filter(
            (existingInvitation: Reference | StoreObject | undefined) =>
              workspaceID !== readField('workspaceID', existingInvitation)
          )
        }
      }
    })
  }, [])

  /** Closes the verification flow */
  const onHandleClose = useCallback(() => {
    setSelectedWorkspace(null)
    onCloseVerificationFlow()
  }, [onCloseVerificationFlow, setSelectedWorkspace])

  /** Handler to delete an access request and close the verification flow */
  const onHandleFinish = useCallback(() => {
    handleDeleteAccessRequest(workspaceIDRef.current)
    onHandleClose()
  }, [handleDeleteAccessRequest, onHandleClose])

  /** Open or close the modal based on the presence of access requests */
  useEffect(() => {
    if (!data) return
    if (data.workspaceAccessRequests?.length > 0) onOpen()
    if (data.workspaceAccessRequests?.length === 0) onClose()
  }, [data, onOpen, onClose])

  return (
    <>
      {isOpenVerificationFlow && selectedWorkspace?.dot && (
        <WorkspaceAccessRequestFlowPopup
          isOpen={isOpenVerificationFlow}
          onClose={onHandleClose}
          workspaceID={workspaceIDRef.current}
          dot={selectedWorkspace.dot}
          onHandleFinish={onHandleFinish}
        />
      )}
      <Modal
        size={isMobile ? 'full' : '2xl'}
        isCentered={!isMobile}
        motionPreset={isMobile ? 'slideInBottom' : 'scale'}
        isOpen={isOpen}
        onClose={onClose}
        title={'Do you belong to this company(s)?'}
        subtitle="You’ve been added on a load for the following companies. Please confirm if you are part of these companies. If yes, you will be asked to verify."
      >
        <VStack
          divider={<StackDivider borderColor={mode('gray.200', 'gray.700')} />}
          spacing={4}
          align="stretch"
          maxHeight="25rem"
          overflow="scroll"
          px={6}
          py={4}
        >
          {data?.workspaceAccessRequests?.map(({ workspace }: IWorkspaceAccessRequest) => {
            if (workspace.id) {
              return (
                <WorkspaceAccessRequest
                  key={workspace.id}
                  workspace={workspace}
                  onAccept={handleAcceptAccessRequest}
                  onDecline={handleDeleteAccessRequest}
                  deleted={deleted}
                  workspaceIDRef={workspaceIDRef}
                />
              )
            }
          })}
        </VStack>
      </Modal>
    </>
  )
})

WorkspaceAccessRequests.displayName = 'WorkspaceAccessRequests'
