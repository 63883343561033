import { Avatar, Box, Circle, Flex, Heading, Text, useColorModeValue as mode } from '@chaine/keychaine'
import React, { Fragment } from 'react'

import { VerifiedIcon } from '../../../_assets/icons'

type TUserDetailsProps = {
  companyDetails: {
    companyType: 'Carrier' | 'Broker'
    dbaName: string
    dotNumber: string
    mcNumber: string
    name: string
    status: 'open'
  }
  url?: string
}

type TSubContent = {
  fontWeight?: string
  id: string | number
  item: React.ReactNode
}

// enum StatusEnum {
//   'close' = 'Close',
//   'open' = 'Open'
// }

/**
 * This component displays the basic details of company
 */
export function CompanyDetailsHeader({ companyDetails, url }: TUserDetailsProps) {
  // const openStatusColor = mode('green.400', 'green.300')
  const textColor = mode('text.secondary', 'text.tertiary')

  // const companyStatusHeadings: TSubContent[] = [
  //   {
  //     id: 'subContent-1',
  //     item: (
  //       <Flex alignItems="center" gap={1}>
  //         <Box color="blue.400">
  //           <Icon as={LinkIcon} boxSize={4} />
  //         </Box>
  //         In network
  //       </Flex>
  //     )
  //   },
  //   {
  //     id: 'subContent-2',
  //     item: (
  //       <Flex alignItems="center" gap={1}>
  //         <Box color="green.300">
  //           <Icon as={CheckIcon} boxSize={4} />
  //         </Box>
  //         Approved
  //       </Flex>
  //     )
  //   }
  // ]

  const companyDetailsHeadings: TSubContent[] = [
    {
      fontWeight: 'semilight',
      id: 1,
      item: <Text color={textColor}>{companyDetails.companyType}</Text>
    },
    {
      fontWeight: 'semilight',
      id: 2,
      item: <Text color={textColor}>{`MC#${' ' + companyDetails.mcNumber || '--'}`}</Text>
    },
    {
      fontWeight: 'semilight',
      id: 3,
      item: <Text color={textColor}>{`DOT# ${companyDetails.dotNumber}`}</Text>
    }
  ]

  return (
    <Flex gap={2}>
      <Avatar
        variant="square"
        src={url}
        size="xl"
        name={companyDetails.name}
        getInitials={() => companyDetails.name.trim().charAt(0)}
      />
      <Box px={2} py={1}>
        <Flex alignItems="center" gap={2}>
          <Heading as="h1" size={{ base: 'sm', lg: 'lg', md: 'md' }} fontWeight="semibold" color="text.lig" gap={2}>
            {companyDetails.name}
          </Heading>
          <VerifiedIcon width={6} height={6} />
        </Flex>
        {/* <SubHeadingContent contents={companyStatusHeadings} contentCount={companyStatusHeadings.length} /> */}
        <SubHeadingContent contents={companyDetailsHeadings} contentCount={companyDetailsHeadings.length} />
        {/* <Heading
          as="h5"
          mt={2}
          fontSize={{ base: 'sm', lg: 'lg', md: 'md' }}
          fontWeight="bold"
          color={openStatusColor}
          alignItems="center"
          gap={2}
        >
          {StatusEnum[companyDetails.status]}
        </Heading> */}
      </Box>
    </Flex>
  )
}

function SubHeadingContent({ contents, contentCount }: { contentCount: number; contents: TSubContent[] }) {
  return (
    <Flex alignItems="center" gap={3} mt={2}>
      {contents?.map((content: TSubContent, id: number) => (
        <Fragment key={content.id}>
          <Heading
            as="h5"
            fontSize={{ base: 'sm', lg: 'lg', md: 'md' }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={1}
            fontWeight={content.fontWeight || 'semibold'}
          >
            {content.item}
          </Heading>
          {id + 1 < contentCount ? <Circle size={1} bg="icon.secondary" mr={0.5} ml={0.5} /> : null}
        </Fragment>
      ))}
    </Flex>
  )
}
