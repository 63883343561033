import '../chat/style/base.css'

import { useQuery } from '@apollo/client'
import { Box, Flex } from '@chaine/keychaine'
import { startCase, toLower } from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'

import { CompanyDetailsHeader, CompanyDetailsTabs } from './components'
import { MOTOR_CARRIER_DATA } from './data'
import { TMotorCarrierData } from './data/queries/types'

export type TDataContext = {
  companyData: TMotorCarrierData | null
  dotNumber: string
  isCompanyDataLoading: boolean
}

export const DataContext = React.createContext<TDataContext>({
  companyData: null,
  dotNumber: '',
  isCompanyDataLoading: false
})

const UnmemoizedCompanyPage = () => {
  const { dotNumber } = useParams()

  const { data, loading } = useQuery(MOTOR_CARRIER_DATA, {
    variables: {
      type: 'DOT',
      value: dotNumber
    }
  })

  return (
    <DataContext.Provider
      value={{
        companyData: data?.motorCarrierData,
        dotNumber: dotNumber || '',
        isCompanyDataLoading: loading
      }}
    >
      <Flex p={2} w="full" direction="column">
        <Box p="4">
          <CompanyDetailsHeader
            url={''}
            companyDetails={{
              companyType: 'Carrier',
              dbaName: startCase(toLower(data?.motorCarrierData?.dbaName?.oldestValue?.value)) || '--',
              dotNumber: dotNumber || '',
              mcNumber: data?.motorCarrierData?.mcNumber || '--',
              name: startCase(toLower(data?.motorCarrierData?.legalName?.oldestValue?.value)) || '--',
              status: 'open'
            }}
          />
        </Box>
        <Box p="4">
          <CompanyDetailsTabs />
        </Box>
      </Flex>
    </DataContext.Provider>
  )
}

const CompanyPage = React.memo(UnmemoizedCompanyPage)

CompanyPage.displayName = 'Company'

export default CompanyPage
