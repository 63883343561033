import { Box, Flex, HStack, Icon, Link, LinkProps, octicons, Text, useColorModeValue as mode } from '@chaine/keychaine'
import React, { useMemo } from 'react'
import { Link as ReactRouterLink, useMatch } from 'react-router-dom'

import { useChat } from '../../../chat/context/chat-context'

interface INavLinkProps extends LinkProps {
  activeOnlyWhenExact?: boolean
  icon: octicons.Icon
  iconColor?: string
  key: string
  navSize: string
  path: string
  title: string
}

export const NavLink = ({ icon, title, navSize, path, iconColor }: INavLinkProps) => {
  const activePath = useMatch(path)
  const { unreadCount } = useChat()
  const displayCount = useMemo(() => {
    return unreadCount > 99 ? `99+` : unreadCount
  }, [unreadCount])

  return (
    <Box minWidth={'100%'} my={1}>
      <Link as={ReactRouterLink} to={path} key={path} _focus={{}}>
        <Flex
          h={8}
          justifyContent={navSize === 'expanded' ? 'start' : 'center'}
          px={4}
          mx={0}
          w="100%"
          borderRadius="4px"
          transition="all 0.3s"
          lineHeight="1.25rem"
          aria-current={activePath ? 'page' : undefined}
          color={mode('gray.500', 'gray.300')}
          _hover={{
            bg: mode('gray.100', 'whiteAlpha.100'),
            borderRadius: 'md'
          }}
          _activeLink={{
            bg: mode('gray.100', 'whiteAlpha.200'),
            borderRadius: 'md',
            color: 'blue.500'
          }}
        >
          <HStack w="100%" position="relative">
            <Icon color={iconColor || 'currentColor'} as={icon} w={4} h={4} />
            {title === 'Inbox' && navSize === 'contracted' && unreadCount && (
              <Flex
                minW={5}
                justifyContent="center"
                px={1}
                borderRadius={'xl'}
                bg="blue.500"
                position="absolute"
                top={0}
              >
                <Text fontSize="sm" color="white">
                  {displayCount}
                </Text>
              </Flex>
            )}
            {title === 'Inbox' && unreadCount ? (
              <Text pr={2} display={navSize === 'contracted' ? 'none' : 'block'}>
                {title} ({displayCount})
              </Text>
            ) : (
              <Text pr={2} display={navSize === 'contracted' ? 'none' : 'block'}>
                {title}
              </Text>
            )}
          </HStack>
        </Flex>
      </Link>
    </Box>
  )
}
