import { inputOutlineStyle } from '../../form/input';
export const Input = {
    parts: ['field', 'addon'],
    variants: {
        base: inputOutlineStyle
    },
    defaultProps: {
        variant: 'base',
        size: 'md'
    }
};
export const NumberInput = {
    parts: ['field', 'addon'],
    variants: {
        base: inputOutlineStyle
    },
    defaultProps: {
        variant: 'base',
        size: 'md'
    }
};
