import { Box, Text, useColorModeValue as mode } from '@chaine/keychaine'
import React, { memo, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../../../_routes/routes-list'
import { authService } from '../../../../auth/data'
import { useAuth } from '../../../../auth/data/use-auth'
import { CompanyType } from '../../../components/setup-workspace/types'

/**
 * Displays the list of header nav list
 */
const HeaderNavList = memo(() => {
  const { selectedWorkspace, setWorkspace } = useAuth()
  const { workspaces } = authService.getWorkspacesByUser()
  const hoverBG = mode('gray.800', 'gray.700')

  useEffect(() => {
    if (!selectedWorkspace) {
      const hasWorkspaces = workspaces && workspaces.length > 0 ? true : false
      if (hasWorkspaces) setWorkspace(workspaces[0])
    }
  }, [selectedWorkspace, setWorkspace, workspaces])

  const activeWorkspace = useMemo(() => selectedWorkspace?.workspace?.workspacename, [selectedWorkspace])

  const navItems = useMemo(() => {
    return workspaces?.length && selectedWorkspace && selectedWorkspace.workspace.workspaceType === CompanyType.Broker
      ? [
          {
            text: 'Inbox',
            to: ROUTES.INBOX
          },
          {
            text: 'Booking',
            to: `/${activeWorkspace}/${ROUTES.POSTS}`
          },
          {
            text: 'Tracking',
            to: activeWorkspace ? `/${activeWorkspace}/${ROUTES.SHIPMENTS}` : `${ROUTES.ASSIGNED_TO_ME}`
          }
        ]
      : [
          {
            text: 'Inbox',
            to: ROUTES.INBOX
          },
          {
            text: 'Booking',
            to: `/${ROUTES.OFFERS}`
          },
          {
            text: 'Tracking',
            to: activeWorkspace ? `/${activeWorkspace}/${ROUTES.SHIPMENTS}` : `${ROUTES.ASSIGNED_TO_ME}`
          }
        ]
  }, [activeWorkspace, selectedWorkspace, workspaces])

  return (
    <React.Fragment>
      {navItems.map((item) => {
        return (
          <Box
            px={3}
            py={1}
            zIndex={99}
            _hover={{
              backgroundColor: { hoverBG },
              borderRadius: 'lg'
            }}
            key={item.text}
          >
            <Link to={item.to}>
              <Text _hover={{ cursor: 'pointer' }} as="button" color="gray.50" fontSize="lg" fontWeight="semibold">
                {item.text}
              </Text>
            </Link>
          </Box>
        )
      })}
    </React.Fragment>
  )
})

HeaderNavList.displayName = 'HeaderNavList'
export default HeaderNavList
