import { gql } from '@apollo/client'

export const OFFER_FRAGMENT = gql`
  fragment OfferFragment on Offer {
    id
    postID
    mcNumber
    dotNumber
    emptyAtPreviousReceiverTime
    location
    amount {
      value
      currency
    }
    message
    user {
      id
      name
      role
      username
      photo
      primaryMembership {
        accessType
        isPrimary
        workspace {
          displayName
          logo
          isVerified
        }
      }
    }
    offerStatus
    created
  }
`
