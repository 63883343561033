import { gql } from '@apollo/client'

import { IWorkspace, MembershipAccessType } from '../../../../types'

export interface IWorkspaceInvitation {
  expirationDate: number
  invitedBy: {
    countryCode: string
    email: string
    id: string
    name: string
    phone: string
    photo: string
  }
  userID: string
  workspace: {
    displayName: string
    id: string
    logo: string
  }
}

/**
 * Query to get the members of a workspace
 */
export const GET_USERS_WORKSPACE_INVITATION = gql`
  query {
    myWorkspaceInvitations {
      workspaceID
      userID
      expirationDate
      workspace {
        id
        logo
        displayName
      }
      invitedBy {
        id
        name
        photo
        email
        phone
        countryCode
      }
    }
  }
`

enum WorkspaceAccessRequestStatusType {
  Declined,
  Pending
}

export interface IWorkspaceAccessRequest {
  accessType: MembershipAccessType
  status: WorkspaceAccessRequestStatusType
  workspace: IWorkspace
  workspaceID: string
}

/**
 * Query to get the workspace access requests
 */
export const GET_USER_WORKSPACE_ACCESS_REQUEST = gql`
  query {
    workspaceAccessRequests {
      workspaceID
      workspace {
        id
        displayName
        logo
        mcNumber
        dot
        workspacename
      }
      accessType
      status
    }
  }
`
