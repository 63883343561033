import { extendTheme } from '@chakra-ui/react';
import * as components from './components';
import * as foundations from './foundations';
import { defaultColors } from './foundations/default-colors';
const config = {
    cssVarPrefix: 'keychaine'
};
export const theme = extendTheme({
    ...foundations,
    colors: defaultColors,
    config,
    components: { ...components },
    fontSizes: {
        xs: '11px',
        sm: '12px',
        md: '14px',
        lg: '16px',
        xl: '18px',
        '2xl': '24px',
        '3xl': '28px',
        '4xl': '36px',
        '5xl': '48px',
        '6xl': '64px'
    }
});
