import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Circle,
  Flex,
  HStack,
  Progress,
  Text,
  useColorModeValue as mode
} from '@chaine/keychaine'
import React, { useCallback } from 'react'

import { IWorkspace } from '../../types'

interface WorkspaceAccessRequestProps {
  deleted: boolean
  onAccept: (workspace: IWorkspace) => void
  onDecline: (workspaceID: string) => void
  workspace: IWorkspace
  workspaceIDRef: React.RefObject<string>
}

/**
 * WorkspaceAccessRequest component displays a single workspace access request and provides the option to accept or decline it.
 */
const WorkspaceAccessRequest = React.memo((props: WorkspaceAccessRequestProps) => {
  const { workspace, workspaceIDRef, deleted, onDecline, onAccept } = props

  /** To accept the request */
  const acceptRequest = useCallback(() => onAccept(workspace), [onAccept, workspace])

  /** To decline the request */
  const declineRequest = useCallback(() => {
    if (workspace.id) onDecline(workspace.id)
  }, [onDecline, workspace.id])

  return (
    <Flex justify="space-between" align="center" w="full" gap={2}>
      <HStack gap={2}>
        <Avatar
          variant="square"
          size={{ base: 'xs', md: 'md' }}
          src={workspace.logo || ''}
          name={workspace?.displayName || ''}
          alignSelf={{ base: 'flex-start', md: 'center' }}
        />
        <Flex flexDirection="column" alignItems="flex-start" gap={1}>
          <HStack>
            <Text
              noOfLines={1}
              color={mode('text.primary', 'text.secondary')}
              fontSize={{ base: 'md', md: 'md' }}
              fontWeight="bold"
            >
              {workspace?.displayName}
            </Text>
          </HStack>
          <HStack>
            {workspace.mcNumber && (
              <Text fontWeight="light" fontSize="sm">
                MC # {workspace.mcNumber}
              </Text>
            )}
            {workspace.mcNumber && <Circle aria-label="circle" size={1} bg="gray" mr={0.5} ml={0.5} />}
            <Text fontWeight="light" fontSize="sm">
              DOT # {workspace.dot}
            </Text>
          </HStack>
        </Flex>
      </HStack>
      {workspaceIDRef.current === workspace.id && deleted ? (
        <Box mt={6}>
          <Progress size="xs" isIndeterminate />
        </Box>
      ) : (
        <Box
          display="flex"
          gap={2}
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'flex-end' }}
        >
          <ButtonGroup>
            <Button variant="primary" size="sm" onClick={acceptRequest}>
              Yes
            </Button>
            <Button size="sm" color={mode(undefined, 'gray.100')} onClick={declineRequest}>
              No
            </Button>
          </ButtonGroup>
        </Box>
      )}
    </Flex>
  )
})

WorkspaceAccessRequest.displayName = 'WorkspaceAccessRequest'
export { WorkspaceAccessRequest }
