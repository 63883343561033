import { gql } from '@apollo/client'

/**
 * Mutation to accept the workspace invitation
 */
export const ACCEPT_WORKSPACE_INVITATION = gql`
  mutation AcceptWorkspaceInvitation($userID: ID!, $workspaceID: ID!, $createdVia: CreatedVia!) {
    acceptWorkspaceInvitation(input: { userID: $userID, workspaceID: $workspaceID, createdVia: $createdVia }) {
      id
    }
  }
`

/**
 * Mutation to decline the workspace invitation
 */
export const DECLINE_WORKSPACE_INVITATION = gql`
  mutation DeclineInvitation($userID: ID!, $workspaceID: ID!) {
    declineInvitation(input: { userID: $userID, workspaceID: $workspaceID }) {
      id
    }
  }
`

/**
 * Mutation to delete workspace access request
 */
export const DELETE_WORKSPACE_ACCESS_REQUEST = gql`
  mutation deleteWorkspaceAccessRequest($workspaceID: ID!) {
    deleteWorkspaceAccessRequest(input: { workspaceID: $workspaceID }) {
      id
    }
  }
`
