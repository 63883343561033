import { ApolloCache, Reference, StoreObject, useMutation, useQuery } from '@apollo/client'
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Modal,
  Progress,
  StackDivider,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
  useDisclosure,
  VStack
} from '@chaine/keychaine'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { UserUtils } from '../../../../_utils'
import { authService } from '../../../../auth/data'
import { Toast } from '../../../components'
import { WorkspaceAccessRequests } from '../../../components/workspace-access-request/workspace-access-requests'
import { CreatedVia } from '../../../types'
import { ACCEPT_WORKSPACE_INVITATION, DECLINE_WORKSPACE_INVITATION } from './data/mutations'
import { GET_USERS_WORKSPACE_INVITATION, IWorkspaceInvitation } from './data/queries'

/**
 * WorkspaceInvitationsReceived component displays all active workspace invitations to the user and provides the option to accept or decline them.
 */
export const WorkspaceInvitationsReceived = React.memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false })
  const workspaceIDRef = useRef<string>('')
  const declineButtonColor = mode(undefined, 'gray.100')
  const borderColor = mode('gray.200', 'gray.700')
  const currentTime = new Date().getTime()

  const { refetchWorkspaces } = authService.getWorkspacesByUser()

  const { showToast } = Toast()

  const { loading, data: { myWorkspaceInvitations: invitations = [] } = {} } = useQuery(GET_USERS_WORKSPACE_INVITATION)

  const [acceptInvitation, { loading: accepting }] = useMutation(ACCEPT_WORKSPACE_INVITATION, {
    onCompleted: () => {
      refetchWorkspaces()
    },
    onError: (acceptInvitationError) => {
      showToast({ status: 'error', title: acceptInvitationError.message })
      onClose()
    },
    update(cache) {
      modifyCache(cache)
    }
  })

  const [declineInvitation, { loading: declining }] = useMutation(DECLINE_WORKSPACE_INVITATION, {
    onCompleted: () => {},
    onError: () => {
      showToast({ status: 'error', title: 'An error occurred while declining the invitation.' })
      onClose()
    },
    update(cache) {
      modifyCache(cache)
    }
  })

  const modifyCache = useCallback((cache: ApolloCache<unknown>) => {
    const workspaceID = workspaceIDRef.current
    cache.modify({
      fields: {
        myWorkspaceInvitations(existingInvitations, { readField }) {
          return existingInvitations.filter(
            (existingInvitation: Reference | StoreObject | undefined) =>
              workspaceID !== readField('workspaceID', existingInvitation)
          )
        }
      }
    })
  }, [])

  const activeInvitations = useMemo(
    () => invitations.filter((invitation: IWorkspaceInvitation) => currentTime < invitation.expirationDate),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invitations]
  )

  const handleAcceptInvitation = useCallback(
    (workspaceID: string, userID: string) => {
      workspaceIDRef.current = workspaceID
      acceptInvitation({ variables: { createdVia: CreatedVia.ChaineWebApp, userID: userID, workspaceID: workspaceID } })
    },
    [acceptInvitation]
  )

  const handleDeclineInvitation = useCallback(
    (workspaceID: string, userID: string) => {
      workspaceIDRef.current = workspaceID
      declineInvitation({ variables: { userID: userID, workspaceID: workspaceID } })
    },
    [declineInvitation]
  )

  useEffect(() => {
    if (activeInvitations.length > 0) onOpen()
    if (activeInvitations.length === 0) onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInvitations.length])

  return (
    <>
      {!loading && !isOpen ? (
        <WorkspaceAccessRequests />
      ) : (
        <Modal
          size={isMobile ? 'full' : '2xl'}
          isCentered={isMobile ? false : true}
          motionPreset={isMobile ? 'slideInBottom' : 'scale'}
          isOpen={isOpen}
          onClose={onClose}
          title={'You have an invite!'}
          subtitle="You have been invited to join a workspace. Workspaces allow you to work together with your team."
        >
          <VStack
            divider={<StackDivider borderColor={borderColor} />}
            spacing={4}
            align="stretch"
            maxHeight="25rem"
            overflow="scroll"
            px={6}
            py={4}
          >
            {activeInvitations.map(({ invitedBy, workspace, userID }: IWorkspaceInvitation) => (
              <Box py={4} px={2} key={`${userID}-${workspace.id}`}>
                <Box
                  display="flex"
                  gap={2}
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'center', md: 'flex-start' }}
                >
                  <Box display="flex" gap={2}>
                    <Avatar size="xs" name={UserUtils.userDisplayName(invitedBy)} src={invitedBy.photo} />
                    <Text fontSize="md" fontWeight="bold">
                      {UserUtils.userDisplayName(invitedBy)}
                    </Text>
                  </Box>
                  <Text>is inviting you to join</Text>
                  <Box display="flex" gap={2}>
                    <Avatar variant="square" size="xs" src={workspace.logo} name={workspace.displayName} />
                    <Text fontSize="md" fontWeight="bold">
                      {workspace.displayName}
                    </Text>
                  </Box>
                </Box>

                {workspaceIDRef.current === workspace.id && (accepting || declining) ? (
                  <Box mt={6}>
                    <Progress size="xs" isIndeterminate />
                  </Box>
                ) : (
                  <Box
                    mt={4}
                    display="flex"
                    gap={2}
                    flexDirection={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'center', md: 'flex-start' }}
                  >
                    <ButtonGroup>
                      <Button variant="primary" size="sm" onClick={() => handleAcceptInvitation(workspace.id, userID)}>
                        Accept
                      </Button>
                      <Button
                        size="sm"
                        color={declineButtonColor}
                        onClick={() => handleDeclineInvitation(workspace.id, userID)}
                      >
                        Decline
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
              </Box>
            ))}
          </VStack>
        </Modal>
      )}
    </>
  )
})

WorkspaceInvitationsReceived.displayName = 'WorkspaceInvitationsReceived'
