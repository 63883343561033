import { gql } from '@apollo/client'

import { UserRole } from '../../../_shared/types'
import { OFFER_FRAGMENT } from '.'

export const POST_OFFER = gql`
  query PostOffer($offerID: ID!, $postID: ID!) {
    postOffer(input: { offerID: $offerID, postID: $postID }) {
      id
      postID
      amount {
        value
        currency
      }
      message
      user {
        id
      }
      workspace {
        id
        displayName
        workspacename
        logo
        isVerified
        workspaceVerificationType
        company {
          id
          companyName
          mcNumber
          phoneNumber
        }
      }
    }
  }
`

export interface PostOfferReturnType {
  amount: {
    currency: string
    value: string
  }
  created: string
  emptyAtPreviousReceiverTime: string

  id: string
  location: string
  mcNumber: string
  message: string
  offerID?: string
  offerStatus?: string
  postID: string
  user: {
    id: string
    name: string
    photo: string
    primaryMembership: {
      accessType: string | null
      isPrimary: boolean | null
      workspace: { displayName: string; isVerified: boolean; logo: string | null }
    } | null
    role: UserRole | null
    username: string
  }
}

export const POST_OFFERS = gql`
  query PostOffers($id: ID!) {
    postOffers(input: { id: $id }) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`

export const COMPANY_INTELLIGENCE = gql`
  query CompanyIntelligence($dotNumber: String!) {
    companyIntelligence(input: { dotNumber: $dotNumber }) {
      telephone {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      faxNumber {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      mailingAddress {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      physicalAddress {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      emailAddress {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      mcsDate {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      mcsMileage {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      mcsMileageYear {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      numberOfDrivers {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      numberOfPowerUnits {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
      oicState {
        past {
          value
          date
        }
        recent {
          value
          date
        }
        associations
        updatedWithin {
          days
          isUpdated
        }
      }
    }
  }
`
