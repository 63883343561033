import { Button, ButtonGroup, ModalContent, ModalKey, ModalOverlay } from '@chaine/keychaine'
import React, { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { UsersWorkspace } from '../../../auth/data/auth-types'
import { CompanyConfirmation } from './company-confirmation'
import { ConfirmationStep } from './confirmation-step'
import { CompanyReturnType, CompanyVerificationMethodsReturnType } from './data/queries'
import { MCOrDOTNumber } from './enter-mc-or-dot-number'
import { handleNext } from './handle-next'
import { ReceiveCodeMethodForPhone } from './receive-code-method-for-phone'
import { EmailAddress } from './receive-code-via-email-address'
import { RequestCustomVerification } from './request-custom-verification'
import { MCOrDOTType } from './select-mc-or-dot-type'
import { SelectVerificationMethod } from './select-verification-method'
import {
  CompanyIdentifierType,
  CompanyType,
  ReceiveCodeMethodType,
  Variant,
  VerificationMethodType,
  WorkspaceProps
} from './types'
import { VerificationCode } from './verification-code'
import { Wrapper } from './wrapper'

interface SetupWorkspaceProps {
  /**
   * The access type of the user (Admin, Owner, Member, Driver)
   */
  accessType?: string
  /**
   * Whether or not the modal is open
   */
  isOpen: boolean

  /**
   * The function to call when the user closes the modal
   */
  onClose: () => void

  /**
   *
   */
  onWorkspaceCreation?: (workspace: UsersWorkspace) => void

  /**
   *
   */
  refetchWorkspaces?: () => void

  /**
   *
   */
  replaceWorkspacenameInURL?: (workspacename: string) => void
  /**
   * The variant for the for the setup workspace component- create a new workspace or get verified
   */
  variant: Variant

  /**
   * The {@link WorkspaceProps details} of the workspace to be verified, or null if creating a new workspace
   */
  workspace: WorkspaceProps | null
}

const UnmemoizedSetupWorkspace = (props: SetupWorkspaceProps) => {
  const { isOpen, onClose, variant, workspace, onWorkspaceCreation, replaceWorkspacenameInURL } = props

  const [step, setStep] = useState(2)

  //go to the previous step
  const previousStepOrSkipStep = useCallback(
    (goToStep: number) => {
      return setStep(goToStep)
    },
    [setStep]
  )

  const [workspaceID, setWorkspaceID] = useState(workspace?.id || uuidv4())

  //either MC# or DOT#
  const [companyIdentifierType, setCompanyIdentifierType] = useState(CompanyIdentifierType.DOT)

  //either Broker or Carrier
  const [companyType, setCompanyType] = useState(CompanyType.Broker)

  //the value of the MC# or DOT#
  const [companyIdentifierValue, setCompanyIdentifierValue] = useState('')

  const [allowableVerificationMethods, setAllowableVerificationMethods] =
    useState<CompanyVerificationMethodsReturnType | null>(null)

  //the type of verification: custom method, email address, phone, etc
  const [verificationMethod, setVerificationMethod] = useState<{ type: VerificationMethodType; value: string } | null>(
    null
  )

  //how the user wants to receive the code: sms, phone, email
  const [receiveCodeMethod, setReceiveCodeMethod] = useState(ReceiveCodeMethodType.SMS)

  /** The company matching the DOT or MC */
  const [companyDetails, setCompanyDetails] = useState<CompanyReturnType | null>(null)

  const [closeAlert, setCloseAlert] = useState<boolean>(false)

  const handleOnClose = () => {
    setCloseAlert(!closeAlert)
  }

  const callHandleNext = useCallback(() => {
    return handleNext({
      companyIdentifierType,
      setStep,
      step,
      verificationMethodType: verificationMethod?.type || null
    })
  }, [step, verificationMethod, companyIdentifierType, setStep])

  const renderStep = () => {
    switch (step) {
      case 2:
        return (
          <MCOrDOTType
            variant={variant}
            workspace={workspace}
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            companyIdentifierType={companyIdentifierType}
            setCompanyIdentifierType={setCompanyIdentifierType}
          />
        )
      case 3:
        return (
          <MCOrDOTNumber
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            companyIdentifierType={companyIdentifierType}
            companyIdentifierValue={companyIdentifierValue}
            setCompanyIdentifierValue={setCompanyIdentifierValue}
            setCompanyDetails={setCompanyDetails}
            setWorkspaceID={setWorkspaceID}
          />
        )
      case 4:
        return (
          <CompanyConfirmation
            step={step}
            setStep={setStep}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            company={companyDetails}
            companyIdentifierType={companyIdentifierType}
            companyIdentifierValue={companyIdentifierValue}
            setAllowableVerificationMethods={setAllowableVerificationMethods}
            workspaceID={workspaceID}
            companyType={companyType}
            setCompanyType={setCompanyType}
          />
        )
      case 5:
        return (
          <SelectVerificationMethod
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            allowableVerificationMethods={allowableVerificationMethods}
            verificationMethod={verificationMethod}
            setVerificationMethod={setVerificationMethod}
            setReceiveCodeMethod={setReceiveCodeMethod}
            receiveCodeMethod={receiveCodeMethod}
            workspaceID={workspaceID}
          />
        )
      case 6:
        return (
          <ReceiveCodeMethodForPhone
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            verificationMethodType={verificationMethod?.type || null}
            receiveCodeMethod={receiveCodeMethod}
            setReceiveCodeMethod={setReceiveCodeMethod}
            workspaceID={workspaceID}
          />
        )
      case 7:
        return (
          <EmailAddress
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            verificationMethod={verificationMethod}
            setEmailVerificationMethod={setVerificationMethod}
            receiveCodeMethod={receiveCodeMethod}
            setReceiveCodeMethod={setReceiveCodeMethod}
            workspaceID={workspaceID}
          />
        )
      case 8:
        return (
          <VerificationCode
            step={step}
            method={receiveCodeMethod}
            type={verificationMethod?.type || VerificationMethodType.Email}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            emailOrPhone={verificationMethod?.value || ''}
            workspaceID={workspaceID}
            company={companyDetails}
          />
        )
      case 9:
        return (
          <RequestCustomVerification
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            companyIdentifierValue={companyIdentifierValue}
            companyIdentifierType={companyIdentifierType}
            workspaceID={workspaceID}
          />
        )
      case 10:
        return (
          <ConfirmationStep
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            onClose={onClose}
            companyIdentifierType={companyIdentifierType}
            verificationMethodType={verificationMethod?.type || null}
            replaceWorkspacenameInURL={replaceWorkspacenameInURL}
            workspaceID={workspaceID}
            onWorkspaceCreation={onWorkspaceCreation}
          />
        )
      default:
        return null
    }
  }

  const handleClickYesButton = () => {
    setCloseAlert(false)
    onClose()
  }

  return (
    <>
      <ModalKey isOpen={isOpen} onClose={handleOnClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          {closeAlert ? (
            <Wrapper title={'Are you sure you want to cancel?'} activeStep={step} backButtonProps={null}>
              <ButtonGroup gap={2}>
                <Button onClick={handleClickYesButton} variant="primary" mt={4}>
                  {'Yes'}
                </Button>
                <Button onClick={handleOnClose} variant="solid" mt={4}>
                  {'No'}
                </Button>
              </ButtonGroup>
            </Wrapper>
          ) : (
            <>{renderStep() as React.ReactElement}</>
          )}
        </ModalContent>
      </ModalKey>
    </>
  )
}

/**
 * Allows a user to setup a new workspace and get verified
 */
export const SetupAndVerifyWorkspaceModal = React.memo(UnmemoizedSetupWorkspace)
