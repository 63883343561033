import { ApolloError, ApolloQueryResult, OperationVariables, useLazyQuery, useMutation, useQuery } from '@apollo/client'

import { ITokenService } from '../../_services/token-service'
import { CreatedVia } from '../../_shared/types/user-types'
import { graphQLErrorParser } from '../../_utils/graphql-error-parser'
import {
  AuthenticateDTO,
  AuthenticateResponseData,
  AuthenticateSessionResponseData,
  AuthResponse,
  InitiateAuthData,
  InitiateAuthDTO,
  IResponse,
  RevokeUserTokenDTO,
  UsersWorkspace
} from './auth-types'
import {
  GET_CHAT_USER_TOKEN,
  INITIATE_AUTH_OR_SIGNUP,
  MY_WORKSPACES,
  REVOKE_USER_TOKEN,
  VERIFY_CODE
} from './queries-mutations'

export interface IAuthService {
  authenticate: () => AuthResponse<
    'useAuthenticate',
    AuthenticateResponseData | AuthenticateSessionResponseData,
    AuthenticateDTO
  >
  getChatUserToken: () => {
    getChatToken: () => void
  }
  getWorkspacesByUser(): {
    isWorkspacesLoading: boolean
    refetchWorkspaces: (
      variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<UsersWorkspace[]>>
    workspaces: UsersWorkspace[]
  }
  initiateAuth: () => AuthResponse<'useInitiateAuth', InitiateAuthData, InitiateAuthDTO>

  revokeUserToken: () => AuthResponse<'useLogout', void, RevokeUserTokenDTO>
}

export class AuthService implements IAuthService {
  public tokenService: ITokenService

  constructor(tokenService: ITokenService) {
    this.tokenService = tokenService
  }

  /**
   * getUsersWorkspace is called to get the active workspace by user
   *
   * @returns workspaces {@link WorkspaceByUser[]}, isWorkspacesLoading : boolean, refetchWorkspaces {@link https://www.apollographql.com/docs/react/data/queries/#refetching}
   */
  getWorkspacesByUser = (): {
    isWorkspacesLoading: boolean
    refetchWorkspaces: (
      variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<UsersWorkspace[]>>
    workspaces: UsersWorkspace[]
  } => {
    const { data, loading: isWorkspacesLoading, refetch: refetchWorkspaces } = useQuery(MY_WORKSPACES)

    return {
      isWorkspacesLoading,
      refetchWorkspaces,
      workspaces: data?.myWorkspaces
    }
  }

  /**
   * Initiate auth is called on the first step of the onboarding flow
   * @returns InitiateAuthResponse
   */
  initiateAuth = (): AuthResponse<'useInitiateAuth', InitiateAuthData, InitiateAuthDTO> => {
    // useMutation<ApolloReturnType<'initiateAuthOrSignup', InitiateAuthData, InitiateAuthDTO>>(
    const [initiateAuthOrSignup] = useMutation(INITIATE_AUTH_OR_SIGNUP)
    const handleInitiateAuth = async (variables: InitiateAuthDTO): Promise<IResponse<InitiateAuthData>> => {
      const input = { ...variables, createdVia: CreatedVia.ChaineWebApp }
      try {
        const {
          data: {
            initiateAuthOrSignup: { ...data }
          }
        } = await initiateAuthOrSignup({
          variables: input
        })
        return {
          data,
          error: false
        }
      } catch (error: unknown) {
        return {
          data: null,
          error: true
        }
      }
    }
    return {
      useInitiateAuth: handleInitiateAuth
    }
  }

  /**
   * useMutation method {@link useMutation}
   * revokeUserToken is called on when onLogout hook gets called
   * @param { @link RevokeUserTokenDTO }
   * @returns <void>
   */
  revokeUserToken = (): AuthResponse<'useLogout', void, RevokeUserTokenDTO> => {
    const [revokeTokenOnLogout] = useMutation(REVOKE_USER_TOKEN)
    const handleRevokeTokenOnLogout = async (variables: RevokeUserTokenDTO): Promise<IResponse<void>> => {
      try {
        if (variables.refreshToken) {
          const {
            data: {
              revokeTokenOnLogout: { ...data }
            }
          } = await revokeTokenOnLogout({
            variables
          })
          return {
            data,
            error: false
          }
        } else {
          return {
            data: null,
            error: false
          }
        }
      } catch (error: unknown) {
        return {
          data: null,
          error: true
        }
      }
    }
    return {
      useLogout: handleRevokeTokenOnLogout
    }
  }

  /**
   * Authenticate is called on the code verification page
   * @params {@link Authenticate}
   * @returns Promise <{@link AuthenticationResponse}>
   */
  authenticate = (): AuthResponse<
    'useAuthenticate',
    AuthenticateResponseData | AuthenticateSessionResponseData,
    AuthenticateDTO
  > => {
    const [verifyCode] = useMutation(VERIFY_CODE)
    const handleAuthentication = async (
      variables: AuthenticateDTO
    ): Promise<IResponse<AuthenticateResponseData | AuthenticateSessionResponseData>> => {
      try {
        const {
          data: {
            verifyCode: { ...data }
          }
        } = await verifyCode({
          variables
        })

        this.tokenService.setToken('Access-Token', data.accessToken)
        this.tokenService.setToken('Refresh-Token', data.refreshToken)

        return {
          data,
          error: false,
          validCode: true
        }
        //deciding to just catch the error. if for some reason cognito/backend services are down, the behavior will be that the user never makes it past the login page.  Unless it is a graphQL network error, in that case app will crash, we need to look into handling this at global level for zen
      } catch (e) {
        const error = graphQLErrorParser(e as ApolloError)[0]
        //in the scenario where the session is returned, this means that the code was invalid (this is not a true error)
        if (error?.code === 'INVALID_VERIFICATION_CODE') {
          const { session } = error.extensions
          return {
            data: { session: session as string },
            error: false,
            message: error.message,
            validCode: false
          }
        } else {
          //this is the scenario where the session is no longer valid, or maybe some other unknown error
          return {
            error: true,
            message: error.message,
            validCode: false
          }
        }
      }
    }

    return {
      useAuthenticate: handleAuthentication // mutation handler
    }
  }

  /**
   * getChatUserToken is used to get Chat User Token
   * {@link https://getstream.io/chat/docs/other-rest/tokens_and_authentication/?language=javascript}
   * @returns getChatToken: function}
   */
  getChatUserToken = (): {
    getChatToken: () => void
  } => {
    // useLazyQuery hook from apollo client to fetch the chat user token
    const [getChatToken, { data }] = useLazyQuery(GET_CHAT_USER_TOKEN)

    // check if the data is returned
    if (data) {
      // set the token in the token service
      this.tokenService.setToken('User-Chat-Token', data?.chatUserToken.token)
    }

    // return the getChatToken function
    return { getChatToken }
  }
}
