/**
 * Configuration
 */
//import manifest from '../../public/manifest.json'

/** Whether we're running on a local desktop or on AWS Lambda */
const isLocal = process.env.VITE_IS_OFFLINE

/**
 * Configuration Options
 *
 * IMPORTANT:
 * The config is injected into the client (browser) and accessible through the {@link useConfig}
 * hook. However, due to this behavior, it is important NOT to expose any sensitive information
 * such as passwords or tokens through the config.
 */

const config = {
  /** Application Config */
  app: {
    API_BASE_URL: String(process.env.VITE_API_BASE_URL),
    APP_PUBLIC_URL: String(process.env.VITE_APP_PUBLIC_URL),
    BASE_OAUTH_CALLBACK_URL: String(process.env.VITE_BASE_OAUTH_CALLBACK_URL),
    BROWSER_NOTIFICATION_ICON: `${process.env.VITE_APP_PUBLIC_URL}/icons/browser-notification-logo.png`,
    BROWSER_NOTIFICATION_SOUND: `${process.env.VITE_APP_PUBLIC_URL}/sounds/notification-tone.mp3`,
    CHAINELY_URL: String(process.env.VITE_CHAINELY_URL),
    /** Where the bundled distribution files (`index.js`, `index.css`) are hosted */
    DIST_URL: isLocal ? 'http://localhost:8080' : String(process.env.VITE_APP_DIST_URL),
    ENV_STAGE: process.env.VITE_ENV_STAGE ? String(process.env.VITE_ENV_STAGE) : 'dev',
    GOOGLE_API_KEY: String(process.env.VITE_GOOGLE_API_KEY),
    GRAPHQL_URL: process.env.VITE_LOCAL_GRAPHQL_URL
      ? String(process.env.VITE_LOCAL_GRAPHQL_URL)
      : String(process.env.VITE_GRAPHQL_URL),
    INTERCOM_APP_ID: String(process.env.VITE_INTERCOM_APP_ID),
    MAPBOX_ACCESS_TOKEN: String(process.env.VITE_MAPBOX_ACCESS_TOKEN),
    /** Where the contents of the `public` folder are hosted (might be the same as `config.app.DIST_URL`) */
    PUBLIC_URL: isLocal ? 'http://localhost:8080' : String(process.env.VITE_APP_PUBLIC_URL),
    STAGE: process.env.VITE_SERVERLESS_STAGE ? String(process.env.VITE_SERVERLESS_STAGE) : 'dev',
    STREAM_APP_ID: String(process.env.VITE_STREAM_APP_ID),
    STREAM_APP_KEY: String(process.env.VITE_STREAM_APP_KEY),
    /** Theme is also loaded from the `manifest.json` */
    THEME_COLOR: '#000000',
    /** Name of the app is loaded from the `manifest.json` */
    TITLE: 'Chaine',
    TYPESENSE_API_KEY: String(process.env.VITE_TYPESENSE_API_KEY),
    TYPESENSE_APP_ID: String(process.env.VITE_TYPESENSE_APP_ID),
    TYPESENSE_POSTS_INDEX: String(process.env.VITE_TYPESENSE_POSTS_INDEX),
    TYPESENSE_PUBLIC_POSTS_SEARCH_API_KEY: String(process.env.VITE_TYPESENSE_PUBLIC_POSTS_SEARCH_API_KEY),
    /** URL to our public API Gateway endpoint */
    URL: isLocal ? `http://localhost:3000/${process.env.VITE_SERVERLESS_STAGE}` : String(process.env.VITE_SSR_API_URL)
  }
}

export type Config = typeof config
export default config
