import { useMutation } from '@apollo/client'
import { ModalContent, ModalKey, ModalOverlay } from '@chaine/keychaine'
import React, { memo, useCallback, useEffect, useState } from 'react'

import { IWorkspaceMember } from '../../../settings/members/data/queries'
import { INITIATE_VERIFICATION_FOR_WORKSPACE_MEMBER } from '../../layouts/application/header/data'
import { IWorkspace } from '../../types'
import { Toast } from '..'
import { SelectEmailOrPhone } from './select-email-or-phone'
import { VerifyCodeForWorkspaceMember } from './verify-code-for-workspace-member'

interface AddCarrierToWorkspaceModalProps {
  constraintWorkspace?: IWorkspace
  isOpen: boolean
  onClose: () => void
  workspaceMembers: IWorkspaceMember[]
}

/**
 * AddCarrierToWorkspaceModal adds a carrier to the workspace
 */
export const AddCarrierToWorkspaceModal = memo((props: AddCarrierToWorkspaceModalProps) => {
  const { constraintWorkspace, isOpen, onClose, workspaceMembers } = props

  const [step, setStep] = useState(1)
  const [isResending, setIsResending] = useState(false)
  const [selectedEmailOrPhone, setSelectedEmailOrPhone] = useState<string | null>(null)
  const [selectedMemberID, setSelectedMemberID] = useState<string | null>(null)

  const { showToast } = Toast()

  const [initiateVerificationForWorkspaceMember, { loading }] = useMutation(
    INITIATE_VERIFICATION_FOR_WORKSPACE_MEMBER,
    {
      onCompleted: () => {
        setIsResending(false)
        setStep(2)
      },
      onError: (error) => {
        showToast({
          status: 'error',
          title: error.message || 'Something went wrong.'
        })
      }
    }
  )

  const getVerificationCode = useCallback(() => {
    const input = {
      workspaceID: constraintWorkspace?.id,
      workspaceMemberUserID: selectedMemberID
    }
    initiateVerificationForWorkspaceMember({ variables: input })
  }, [selectedMemberID, constraintWorkspace?.id, initiateVerificationForWorkspaceMember])

  const resendCode = useCallback(() => {
    getVerificationCode()
    setIsResending(true)
  }, [setIsResending, getVerificationCode])

  useEffect(() => {
    const selectedMember = workspaceMembers.find((member) => member?.user?.id === selectedMemberID)
    if (selectedMember) setSelectedEmailOrPhone(selectedMember?.user?.email || '')
  }, [selectedMemberID, workspaceMembers])

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SelectEmailOrPhone
            carrierWorkspaceDisplayName={constraintWorkspace?.displayName || ''}
            workspaceMembers={workspaceMembers}
            setSelectedMemberID={setSelectedMemberID}
            selectedMemberID={selectedMemberID}
            gettingCode={loading}
            getVerificationCode={getVerificationCode}
          />
        )
      case 2:
        return (
          <VerifyCodeForWorkspaceMember
            constraintWorkspaceID={constraintWorkspace?.id || ''}
            isResending={isResending}
            selectedEmailOrPhone={selectedEmailOrPhone}
            onClose={onClose}
            resendCode={resendCode}
          />
        )
      default:
        return null
    }
  }

  return (
    <ModalKey isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent>{renderStep() as React.ReactElement}</ModalContent>
    </ModalKey>
  )
})

AddCarrierToWorkspaceModal.displayName = 'AddCarrierToWorkspaceModal'
