import { makeVar, ReactiveVar, useApolloClient, useQuery } from '@apollo/client'
import { useToast } from '@chaine/keychaine'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { ROUTES } from '../../_routes/routes-list'
import config from '../../_server/config'
import { UserStatus } from '../../_shared/types'
import { logout as logoutAnalytics } from '../../_utils/analytics-context/analytics-service'
import { authService, tokenService } from '.'
import {
  AuthenticateDTO,
  AuthenticateResponseData,
  AuthenticateSessionResponseData,
  InitiateAuthDTO,
  IResponse,
  UsersWorkspace,
  Workspace
} from './auth-types'
import { MeReturnType, MY_DETAILS, MY_WORKSPACES } from './queries-mutations'

type AuthUserDetails = {
  emailOrPhone: string
  session?: string
  /**
   * The user's status (e.g. "Registered")
   */
  status?: UserStatus
  userID: string
}

interface AuthenticationHooks {
  authUserDetails: AuthUserDetails | null
  doesUserBelongToWorkspace: (workspacename: string) => Promise<boolean>
  isAuthenticated: boolean
  isUserAdminOrOwner: (role: string) => boolean
  loading: boolean
  loadingUserDetails: boolean
  onAuthInitiation: (emailOrPhone: InitiateAuthDTO) => Promise<{ error: boolean }>
  onLogin: (params: AuthenticateDTO) => Promise<IResponse<AuthenticateResponseData | AuthenticateSessionResponseData>>

  onLogout: () => Promise<void>
  onValidWorkspace: (workspacename: string) => Promise<boolean>
  refetchUserDetails: () => void
  selectedWorkspace: UsersWorkspace | null | undefined
  setWorkspace: (workspace: UsersWorkspace | null) => void
  userDetails: MeReturnType
}

export const selectedWorkspaceVar = makeVar(null) as ReactiveVar<Workspace | null>
export const userDetailsVar = makeVar(null) as ReactiveVar<MeReturnType | null>

const AuthContext = React.createContext<AuthenticationHooks>({} as AuthenticationHooks)
/**
 * Refer to articles for AuthProvider implementation
 * @see {@link https://www.robinwieruch.de/react-router-authentication/ article} for more information
 * @see {@link https://ui.dev/react-router-protected-routes-authentication article} for more information
 */
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const client = useApolloClient()

  const [userDetails, setUserDetails] = useState<MeReturnType | null>(null)

  //user details used during the authentication process only
  const [authUserDetails, setAuthUserDetails] = useState<AuthUserDetails | null>(null)

  const [selectedWorkspace, setSelectedWorkspace] = useState<UsersWorkspace | null | undefined>(() =>
    tokenService.getLocalStoreData('selectedWorkspace')
  )

  const [registrationStatus, setRegistrationStatus] = useState<string>('Registered')
  const [loading, setIsLoading] = useState<boolean>(false)

  //whether or not the user is authenticated
  const isAuthenticated = tokenService.isAuthenticated()

  const toast = useToast()

  /**
   * Determines whether the MY_DETAILS query should be called
   *
   * If the function returns false, that means that the user details will not be fetched
   */
  const skipFetchingUserDetails = () => {
    if (isAuthenticated && !userDetails) return false
    if (authUserDetails?.session) return true
    return false
  }

  const { shutdown: shutDownIntercom, update } = useIntercom()

  const { loading: loadingUserDetails, refetch: refetchUserDetails } = useQuery<{ me: MeReturnType }>(MY_DETAILS, {
    notifyOnNetworkStatusChange: true,

    onCompleted: ({ me }) => {
      if (me) {
        setUserDetails(me)
        userDetailsVar(me)
        //update intercom with the user's details
        update({
          customAttributes: {
            app_id: config.app.INTERCOM_APP_ID,
            company: {
              company_type: selectedWorkspace?.workspace?.workspaceType,
              dot: selectedWorkspace?.workspace?.dot,
              id: selectedWorkspace?.workspace?.id,
              mc: selectedWorkspace?.workspace?.mcNumber,
              name: selectedWorkspace?.workspace?.displayName,
              workspacename: selectedWorkspace?.workspace?.workspacename
            },
            new_platform: true,
            phone: me.phone,
            primary_workspace: me.primaryMembership?.workspace?.displayName,
            role: me.role,
            tags: 'New platform',
            workspace_access: selectedWorkspace?.accessType
          },
          email: me.email,
          name: me.name ?? '',
          userId: me.id
        })
      } else {
        setUserDetails(null)
        userDetailsVar(null)
      }
    },
    //will skip if skipFetchingUserDetails returns true
    skip: skipFetchingUserDetails()
  })

  const { useInitiateAuth } = authService.initiateAuth()
  const { useAuthenticate } = authService.authenticate()
  const { refetchWorkspaces, workspaces } = authService.getWorkspacesByUser()
  const { useLogout } = authService.revokeUserToken()
  const { getChatToken } = authService.getChatUserToken()

  const useAuthInitiation: AuthenticationHooks['onAuthInitiation'] = async (
    emailOrPhone: InitiateAuthDTO
  ): Promise<{ error: boolean }> => {
    //get details associated with the email or phone number
    const { data: useAuthInitiationData, error } = await useInitiateAuth(emailOrPhone)

    //set loading to true
    setIsLoading(true)

    //set the user's details so that it is available in react context (important to access the session)
    setAuthUserDetails(useAuthInitiationData ? useAuthInitiationData : null)

    //reset loading to false
    setIsLoading(false)

    return { error }
  }
  /**
   * handleUserRolePermitted is used to check if the user has permitted role to access or update workspace details.
   * @param role role is user's role from the selected workspace.
   * @returns boolean
   */
  const handleUserAccessTypePermitted: AuthenticationHooks['isUserAdminOrOwner'] = (accessType: string): boolean => {
    if (accessType === 'Admin' || accessType === 'Owner') return true

    return false
  }

  /**
   * This function is used to validate if the workspace name belongs to the user of not.
   * @param workspacename workspacename is the unique name of any workspace that we are getting from URL.
   * @returns boolean
   */
  const handleDoesUserBelongToWorkspace: AuthenticationHooks['doesUserBelongToWorkspace'] = async (
    workspacename: string
  ): Promise<boolean> => {
    /**
     * Refer to articles for client.readQuery
     * @see {@link https://www.apollographql.com/docs/react/caching/cache-interaction/ article} for more information
     */
    let cachedWorkspaces = client.readQuery({
      query: MY_WORKSPACES
    })
    if (cachedWorkspaces === null) {
      const response = await refetchWorkspaces()
      cachedWorkspaces = response.data
    }

    const isValidWorkspace =
      cachedWorkspaces?.myWorkspaces?.find((workspace: UsersWorkspace) => {
        return workspace.workspace?.workspacename === workspacename
      }) !== undefined

    return isValidWorkspace || false
  }

  /**
   * handleValidWorkspace is used to validate the workspace name that we get from URL is really belongs to the user of not.
   * @param workspacename workspacename is the unique name of any workspace that we are getting from URL.
   * @returns Promise<boolean>
   */
  const handleValidWorkspace: AuthenticationHooks['onValidWorkspace'] = async (
    workspacename: string
  ): Promise<boolean> => {
    /**
     * Refer to articles for client.readQuery
     * @see {@link https://www.apollographql.com/docs/react/caching/cache-interaction/ article} for more information
     */
    let cachedWorkspaces = client.readQuery({
      query: MY_WORKSPACES
    })
    if (cachedWorkspaces === null) {
      const response = await refetchWorkspaces()
      cachedWorkspaces = response.data
    }

    let isValidWorkspace = false

    cachedWorkspaces?.myWorkspaces?.map((workspace: UsersWorkspace) => {
      if (workspace.workspace?.workspacename === workspacename) {
        handleSetWorkspace(workspace)
        isValidWorkspace = true
      }
    })
    return isValidWorkspace
  }

  /**
   * handleWorkspaceDetails is used to set the selected workspace into local store
   * @param workspace workspace is the object that contains the details of selected workspace that needs to be store in local storage {@link UsersWorkspace}.
   * @returns Promise<void>
   */
  const handleSetWorkspace: AuthenticationHooks['setWorkspace'] = async (
    workspace: UsersWorkspace | null
  ): Promise<void> => {
    selectedWorkspaceVar(workspace?.workspace || null)
    setSelectedWorkspace(workspace)
    tokenService.setLocalStoreData<UsersWorkspace | null>('selectedWorkspace', workspace)
  }

  const useLogin: AuthenticationHooks['onLogin'] = async (
    params: AuthenticateDTO
  ): Promise<IResponse<AuthenticateResponseData | AuthenticateSessionResponseData>> => {
    //set loading to true
    setIsLoading(true)

    //attempt to authenticate the user's otp code
    const { data: useAuthenticateData, error, message, validCode } = await useAuthenticate(params)

    let status = UserStatus.REGISTERED
    if (error) {
      setAuthUserDetails(null)
    } else {
      status = useAuthenticateData?.status || status

      setRegistrationStatus(status)

      if (authUserDetails)
        setAuthUserDetails({
          ...authUserDetails,
          session: useAuthenticateData?.session,
          status: status
        })

      /**
       * Get user chat token for Get stream SDK and store the token in the local storage
       */
      getChatToken()
    }
    //reset loading to false
    setIsLoading(false)

    setSelectedWorkspace(undefined)

    return { data: useAuthenticateData, error, message, status, validCode }
  }

  useMemo(() => {
    /**
     * Ensure the workspace selected by the user are synchronized with the workspace data by fetching the selected workspace from local storage and updating
     */
    if (workspaces && workspaces.length > 0) {
      const selectedWorkspaceData = tokenService.getLocalStoreData('selectedWorkspace')
      const activeWorkspace =
        selectedWorkspaceData &&
        workspaces.find(
          (userWorkspace: UsersWorkspace) => userWorkspace?.workspace?.id === selectedWorkspaceData?.workspace?.id
        )
      if (activeWorkspace) {
        handleSetWorkspace(activeWorkspace)
      }
    }
  }, [workspaces])

  /**
   * setup hook called onLogout in AuthenticationHooks
   * onLogout hook is called on when user will click on sign-out menu
   * @param {@link RevokeUserTokenDTO}
   * @returns <void>
   */
  const useHandleLogout: AuthenticationHooks['onLogout'] = async (): Promise<void> => {
    toast.closeAll()
    logoutAnalytics() // logout of the analytics sdk
    const refreshToken = tokenService.getToken('Refresh-Token') || ''
    client.clearStore()
    useLogout({ refreshToken })
    setUserDetails(null)
    setAuthUserDetails(null)
    selectedWorkspaceVar(null)
    setSelectedWorkspace(null)
    userDetailsVar(null)
    tokenService.clearLocalStore()
    shutDownIntercom()
    navigate(ROUTES.LOGIN)
  }

  const value = {
    authUserDetails,
    doesUserBelongToWorkspace: handleDoesUserBelongToWorkspace,
    isAuthenticated,
    isUserAdminOrOwner: handleUserAccessTypePermitted,
    loading,
    loadingUserDetails,
    onAuthInitiation: useAuthInitiation,
    onLogin: useLogin,
    onLogout: useHandleLogout,
    onValidWorkspace: handleValidWorkspace,
    refetchUserDetails: refetchUserDetails,
    registrationStatus,
    selectedWorkspace,
    setWorkspace: handleSetWorkspace,
    userDetails: userDetails as MeReturnType
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

/**
 * All {@link AuthenticationHooks authentication hooks}
 * @userDetails details associated with the user
 * @authUserDetails user state shared between the signup and onboarding pages during the signup login process
 * @onLogin logs a user in after code verification
 * @onAuthInitiation initiates authentication process (sends OTP code)
 * @onLogout logs a user out
 * @loading loading values from these hooks
 * @isAuthenticated will be true if the user is currently authenticated {@link tokenService.isAuthenticated}
 * @selectedWorkspace will return the selected workspace stored in the local storage with key selectedWorkspace {@link tokenService.getLocalStoreData}
 * @setWorkspace will be used to store selected workspace in the local storage with key selectedWorkspace {@link tokenService.setLocalStoreData}
 * @onValidWorkspace validates whether the workspace name belongs to the requested user or not, will returns true in case workspace belongs to the user else false.
 * @isUserAdminOrOwner will be true if the passed role is permitted to access the workspace details else false
 */
export const useAuth = (): AuthenticationHooks => {
  return React.useContext(AuthContext)
}
