import { ApolloError, useMutation } from '@apollo/client'
import { Alert, VStack } from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'

import { workspaceVerified } from '../../../_shared/constants/analytics-events'
import { useAnalytics } from '../../../_utils/analytics-context'
import { CodeVerificationInput } from '../code-verification-input'
import {
  SEND_VERIFICATION_CODE,
  SendVerificationCodeInput,
  VERIFY_CODE_FOR_VERIFIED_STATUS,
  VerifyCodeForVerifiedStatusInput
} from './data/mutations'
import { CompanyReturnType } from './data/queries'
import { ModalStepProps, ReceiveCodeMethodType, VerificationMethodType } from './types'
import { Wrapper } from './wrapper'
interface VerificationCodeProps extends ModalStepProps {
  /**
   * The type of identifier the company wants to use to verify (MC or DOT)
   */
  company: CompanyReturnType | null
  /**
   * The user's chosen verification method value (their phone number or email)
   */
  emailOrPhone: string
  /**
   * The strategy to use for verification (e.g. send email or text, make phone call
   */
  method: ReceiveCodeMethodType

  /**
   * The type of value for the verification (e.g. email or phone number)
   */
  type: VerificationMethodType

  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * The user enters the verification code to complete the verification process
 */
export const UnmemoizedVerificationCode = (props: VerificationCodeProps) => {
  const { handleNext, emailOrPhone, step, type, method, workspaceID } = props

  const { captureEvent } = useAnalytics()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [, setSubtext] = useState<string | null>(null)

  const subTextMessage =
    method === 'PhoneCall'
      ? `We are calling on ${emailOrPhone} with the code`
      : `We just sent a verification code to ${emailOrPhone}`

  const [verifyCodeForVerifiedStatus, { loading: verifyCodeStatusLoading, error: verifyCodeStatusError }] = useMutation<
    void,
    VerifyCodeForVerifiedStatusInput
  >(VERIFY_CODE_FOR_VERIFIED_STATUS, {
    onCompleted: () => {
      // we refetch user details in case the user is auto-verified
      captureEvent(workspaceVerified)
      handleNext()
    }
  })

  const [sendVerificationCode, { loading: sendVerificationCodeLoading }] = useMutation<void, SendVerificationCodeInput>(
    SEND_VERIFICATION_CODE
  )

  useEffect(() => {
    if (verifyCodeStatusError) setErrorMessage(verifyCodeStatusError.message)
  }, [verifyCodeStatusError, setErrorMessage])

  async function submit(pinInputValue: string) {
    try {
      await verifyCodeForVerifiedStatus({
        context: {
          headers: {
            'ws-id': workspaceID || ''
          }
        },
        variables: { code: pinInputValue }
      })

      return { error: false }
    } catch (e) {
      return { error: true }
    }
  }

  /** Resends the verification code */
  const resendCode = async () => {
    const variables =
      type === VerificationMethodType.CompanyEmail
        ? { method, type, value: emailOrPhone, workspaceID }
        : { method, type, workspaceID }
    try {
      await sendVerificationCode({
        context: {
          headers: {
            'ws-id': workspaceID || ''
          }
        },
        variables
      })
      setSubtext(subTextMessage)
    } catch (e) {
      setErrorMessage((e as ApolloError).message)
    }
  }

  return (
    <Wrapper title="Enter the code" subtitle={subTextMessage} activeStep={step} backButtonProps={null}>
      <VStack width="100%" alignItems="center" py={4} spacing="4">
        {verifyCodeStatusError && (
          <Alert pb={4} status="error" description={errorMessage} closeButton alertIcon width="50px" />
        )}
        <CodeVerificationInput
          emailOrPhone={emailOrPhone}
          errorMessage={null}
          loading={verifyCodeStatusLoading || sendVerificationCodeLoading}
          onResendCode={resendCode}
          onSubmit={submit}
        />
      </VStack>
    </Wrapper>
  )
}

export const VerificationCode = React.memo(UnmemoizedVerificationCode)
