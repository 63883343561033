import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'

import config from '../../_server/config'
import { ITokenService, TokenType } from '../../_services/token-service'
interface IAPIConfig<D> extends AxiosRequestConfig<D> {
  isAuthorizationRequired?: boolean
  tokenType?: TokenType
}
export abstract class BaseAPI {
  private axiosInstance: AxiosInstance

  public tokenService: ITokenService

  constructor(tokenService: ITokenService) {
    this.tokenService = tokenService

    this.axiosInstance = axios.create({
      baseURL: config.app.API_BASE_URL,
      withCredentials: false
    })
    this.enableInterceptors()
  }

  private enableInterceptors(): void {
    this.axiosInstance.interceptors.response.use(this.getSuccessResponseHandler())
  }

  private getSuccessResponseHandler() {
    return (response: AxiosResponse) => {
      return response
    }
  }

  protected put<D, T>({ url, data, params, headers, isAuthorizationRequired = true }: IAPIConfig<D>): AxiosPromise<T> {
    if (isAuthorizationRequired) {
      headers = {
        ...headers
      }
    }
    return this.axiosInstance({
      data: data,
      headers: headers,
      method: 'PUT',
      params: params,
      url: `${url}`
    })
  }

  /**
   * GET HTTP request method
   * @param param.url url where to make the request
   * @param param.params url params (optional)
   * @param param.headers headers (optional)
   * @param param.isAuthorizationRequired Defaults to true. Gets Access-Token and adds an Authorization header
   * @returns
   */
  protected get<T>({
    url,
    params,
    headers = {},
    isAuthorizationRequired = true
  }: IAPIConfig<undefined>): AxiosPromise<T> {
    let additionHeaders = {}
    if (isAuthorizationRequired) {
      additionHeaders = {
        Authorization: this.tokenService.getToken('Access-Token')
      }
    }
    return this.axiosInstance({
      headers: {
        ...headers,
        ...additionHeaders
      },
      method: 'GET',
      params: params,
      url: `${url}`
    })
  }

  /**
   * POST HTTP request method
   * @param param.url url where to make the request
   * @param param.data data for the post request
   * @param param.params url params (optional)
   * @param param.headers headers (optional)
   * @param param.isAuthorizationRequired Defaults to true. Gets Access-Token and adds an Authorization header
   * @returns
   */
  protected post<D, T>({
    url,
    data,
    params,
    headers = {},
    isAuthorizationRequired = true,
    tokenType = 'Access-Token'
  }: IAPIConfig<D>): AxiosPromise<T> {
    let additionHeaders = {}
    if (isAuthorizationRequired) {
      additionHeaders = {
        Authorization: this.tokenService.getToken(tokenType)
      }
    }
    return this.axiosInstance({
      data: data,
      headers: {
        ...headers,
        ...additionHeaders
      },
      method: 'POST',
      params: params,
      url: `${url}`
    })
  }
}
