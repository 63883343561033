import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { COMPANY_INTELLIGENCE } from '../../../booking/data'
import { TCompanyIntelligence } from '../../../booking/types'
import { DataContext } from '../../index'
import CompanyPageLayout, { CompanySectionLayout } from '../layout'
import { AssessmentCard, AuthorityCard, DetailsCard, IntelligenceCard } from '../overview'

/**
 * This tab includes basic information about company
 * for example - authority details, assessment details and its personal details like telephone, email, address
 */
export function OverviewTabPanel() {
  const { companyData } = useContext(DataContext)
  const { dotNumber } = useParams()
  const { data } = useQuery(COMPANY_INTELLIGENCE, {
    variables: {
      dotNumber: dotNumber
    }
  })

  function getAllAssociations() {
    let associations: string[] = []
    const values = data?.companyIntelligence && Object.values(data?.companyIntelligence as TCompanyIntelligence)
    values?.forEach((value: { associations: string[] }) => {
      if (value?.associations?.length > 0) {
        associations = [...associations, ...value.associations]
      }
    })
    return associations
  }

  function getUpdatedWithin() {
    let updatedWithin: string[] = []
    const keys = data?.companyIntelligence && Object.keys(data?.companyIntelligence as TCompanyIntelligence)
    keys?.forEach((key: string) => {
      data?.companyIntelligence[key]?.updatedWithin?.length > 0 &&
        data.companyIntelligence[key].updatedWithin.forEach((val: { days: string; isUpdated: boolean }) => {
          if (val?.isUpdated) {
            updatedWithin = [
              ...updatedWithin,
              `The ${key[0].toUpperCase() + key.substring(1)} is changed within ${val.days} days`
            ]
          }
        })
    })
    return updatedWithin
  }

  return (
    <React.Fragment>
      <CompanyPageLayout
        leftChildren={
          <CompanySectionLayout gap={8}>
            <IntelligenceCard
              contents={[
                {
                  heading: 'FMCSA Census Changes (past 6 months)',
                  items: getUpdatedWithin()
                },
                {
                  heading: 'Associations',
                  items: getAllAssociations()
                }
              ]}
            />
            <DetailsCard
              contents={[
                {
                  content: `+1 ${companyData?.telephone?.oldestValue?.value}`,
                  heading: 'Telephone'
                },
                {
                  content: companyData?.emailAddress?.oldestValue?.value?.toLocaleLowerCase(),
                  heading: 'Email Address'
                },
                {
                  content: (
                    <>
                      {companyData?.mailingStreet?.oldestValue?.value} <br />{' '}
                      {companyData?.mailingCity?.oldestValue?.value} <br />{' '}
                      {companyData?.mailingState?.oldestValue?.value}, {companyData?.mailingCountry?.oldestValue?.value}{' '}
                      {companyData?.mailingZip?.oldestValue?.value} <br />
                      United States
                    </>
                  ),
                  heading: 'Mailing Address'
                },
                {
                  content: (
                    <>
                      {companyData?.physicalStreet?.oldestValue?.value} <br />{' '}
                      {companyData?.physicalCity?.oldestValue?.value} <br />{' '}
                      {companyData?.physicalState?.oldestValue?.value},{' '}
                      {companyData?.physicalCountry?.oldestValue?.value} {companyData?.physicalZip?.oldestValue?.value}{' '}
                      <br />
                      United States
                    </>
                  ),
                  heading: 'Physical Address'
                }
              ]}
            />
          </CompanySectionLayout>
        }
        rightChildren={
          <CompanySectionLayout gap={8}>
            {companyData?.carrierAuthority?.oldestValue?.value && (
              <AssessmentCard
                contents={[
                  // {
                  //   isDone: true,
                  //   label: 'Inactive Operating Status'
                  // },
                  {
                    isDone: companyData?.carrierAuthority?.oldestValue?.value?.commonAuthorityStatus == 'Active',
                    label: 'Active Authority'
                  }
                  // {
                  //   isDone: true,
                  //   label: 'Active insurance'
                  // },
                  // {
                  //   isDone: true,
                  //   label: 'Inspected past 24 months'
                  // },
                  // {
                  //   isDone: true,
                  //   label: 'Acceptable safety rating'
                  // },
                  // {
                  //   isDone: true,
                  //   label: '8 y/o authority'
                  // }
                ]}
              />
            )}
            {companyData?.carrierAuthority?.oldestValue?.value && (
              <AuthorityCard
                contents={[
                  {
                    label: 'Authority',
                    value:
                      companyData?.carrierAuthority?.oldestValue?.value?.authorizedForBroker == 'true'
                        ? 'Broker'
                        : 'Carrier'
                  },
                  {
                    label: 'Broker Authority Status',
                    value: companyData?.carrierAuthority?.oldestValue?.value?.brokerAuthorityStatus
                  },
                  {
                    label: 'Common Authority Status',
                    value: companyData?.carrierAuthority?.oldestValue?.value?.commonAuthorityStatus
                  },
                  {
                    label: 'Contract Authority Status',
                    value: companyData?.carrierAuthority?.oldestValue?.value?.contractAuthorityStatus
                  }
                ]}
              />
            )}
            {/* <AdditionalInformationCard
              heading={'Inspection'}
              contents={[
                {
                  key: 'inspection-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'inspection-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
            {/* <AdditionalInformationCard
              heading={'Violations'}
              contents={[
                {
                  key: 'violations-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'violations-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
            {/* <AdditionalInformationCard
              heading={'Crashes'}
              contents={[
                {
                  key: 'crashes-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'crashes-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
          </CompanySectionLayout>
        }
      />
      {/* <InspectionMapCard /> */}
    </React.Fragment>
  )
}
