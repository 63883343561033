import { useQuery } from '@apollo/client'
import { Button, ButtonGroup, ModalContent, ModalKey, ModalOverlay, PageSpinner } from '@chaine/keychaine'
import React, { useCallback, useState } from 'react'

import { ConfirmationStep } from '../setup-workspace/confirmation-step'
import { CompanyVerificationMethodsReturnType, GET_COMPANY_VERIFICATION_METHODS } from '../setup-workspace/data/queries'
import { handleNext } from '../setup-workspace/handle-next'
import { ReceiveCodeMethodForPhone } from '../setup-workspace/receive-code-method-for-phone'
import { EmailAddress } from '../setup-workspace/receive-code-via-email-address'
import { SelectVerificationMethod } from '../setup-workspace/select-verification-method'
import { CompanyIdentifierType, ReceiveCodeMethodType, VerificationMethodType } from '../setup-workspace/types'
import { VerificationCode } from '../setup-workspace/verification-code'
import { Wrapper } from '../setup-workspace/wrapper'

interface IWorkspaceAccessRequestFlowPopup {
  companyIdentifierType?: CompanyIdentifierType
  dot: string
  isOpen: boolean
  onClose: () => void
  onHandleFinish: () => void
  replaceWorkspacenameInURL?: (workspacename: string) => void
  workspaceID: string
}

/**
 * Workspace verification process component that manages the verification steps for a workspace.
 */
const UnmemoizedWorkspaceAccessRequestFlowPopup = (props: IWorkspaceAccessRequestFlowPopup) => {
  const { dot, isOpen, onClose, onHandleFinish, workspaceID, companyIdentifierType = CompanyIdentifierType.DOT } = props

  const [step, setStep] = useState(5)
  const [allowableVerificationMethods, setAllowableVerificationMethods] =
    useState<CompanyVerificationMethodsReturnType | null>(null)
  const [verificationMethod, setVerificationMethod] = useState<{ type: VerificationMethodType; value: string } | null>(
    null
  )
  const [receiveCodeMethod, setReceiveCodeMethod] = useState(ReceiveCodeMethodType.SMS)
  const [closeAlert, setCloseAlert] = useState<boolean>(false)

  const { loading } = useQuery<{ companyVerificationMethods: CompanyVerificationMethodsReturnType }>(
    GET_COMPANY_VERIFICATION_METHODS,
    {
      onCompleted: ({ companyVerificationMethods }) => {
        setAllowableVerificationMethods(companyVerificationMethods)
      },
      variables: {
        companyIdentifier: {
          type: companyIdentifierType,
          value: dot
        },
        workspaceID: workspaceID
      }
    }
  )

  const previousStepOrSkipStep = useCallback((goToStep: number) => setStep(goToStep), [setStep])

  const callHandleNext = useCallback(() => {
    return handleNext({
      companyIdentifierType,
      setStep,
      step,
      verificationMethodType: verificationMethod?.type || null
    })
  }, [step, verificationMethod, companyIdentifierType, setStep])

  const renderStep = () => {
    switch (step) {
      case 5:
        return (
          <SelectVerificationMethod
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            allowableVerificationMethods={allowableVerificationMethods}
            verificationMethod={verificationMethod}
            setVerificationMethod={setVerificationMethod}
            setReceiveCodeMethod={setReceiveCodeMethod}
            receiveCodeMethod={receiveCodeMethod}
            workspaceID={workspaceID}
            hideOtherMethods={true}
          />
        )
      case 6:
        return (
          <ReceiveCodeMethodForPhone
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            verificationMethodType={verificationMethod?.type || null}
            receiveCodeMethod={receiveCodeMethod}
            setReceiveCodeMethod={setReceiveCodeMethod}
            workspaceID={workspaceID}
          />
        )
      case 7:
        return (
          <EmailAddress
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            verificationMethod={verificationMethod}
            setEmailVerificationMethod={setVerificationMethod}
            receiveCodeMethod={receiveCodeMethod}
            setReceiveCodeMethod={setReceiveCodeMethod}
            workspaceID={workspaceID}
          />
        )
      case 8:
        return (
          <VerificationCode
            step={step}
            method={receiveCodeMethod}
            type={verificationMethod?.type || VerificationMethodType.Email}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            emailOrPhone={verificationMethod?.value || ''}
            workspaceID={workspaceID}
            company={null}
          />
        )
      case 10:
        return (
          <ConfirmationStep
            step={step}
            previousStepOrSkipStep={previousStepOrSkipStep}
            handleNext={callHandleNext}
            onClose={onHandleFinish}
            companyIdentifierType={companyIdentifierType}
            verificationMethodType={verificationMethod?.type || null}
            workspaceID={workspaceID}
          />
        )
      default:
        return null
    }
  }

  const handleClickYesButton = () => {
    setCloseAlert(false)
    onClose()
  }

  return (
    <ModalKey isOpen={isOpen} onClose={() => setCloseAlert(!closeAlert)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        {loading ? (
          <PageSpinner />
        ) : closeAlert ? (
          <Wrapper title="Are you sure you want to cancel?" activeStep={step} backButtonProps={null}>
            <ButtonGroup gap={2}>
              <Button onClick={handleClickYesButton} variant="primary" mt={4}>
                Yes
              </Button>
              <Button onClick={() => setCloseAlert(false)} variant="solid" mt={4}>
                No
              </Button>
            </ButtonGroup>
          </Wrapper>
        ) : (
          renderStep()
        )}
      </ModalContent>
    </ModalKey>
  )
}

export const WorkspaceAccessRequestFlowPopup = React.memo(UnmemoizedWorkspaceAccessRequestFlowPopup)
