import { MessageAttachments } from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'

import config from '../_server/config'
import { ITokenService, TokenType } from '../_services/token-service'
import { BaseAPI } from '../_shared/infra/base-api'

// BrowserNotificationService extends BaseAPI
export class MessageService extends BaseAPI {
  loggedInUserID: string | null

  constructor(tokenService: ITokenService) {
    super(tokenService)
    this.loggedInUserID = this.tokenService.getUserID()
  }

  public async sendMessage({
    attachments,
    channelID,
    channelType,
    html,
    mentionedUsers,
    messageID,
    messageText
  }: {
    attachments?: MessageAttachments<DefaultStreamChatGenerics>
    channelID?: string
    channelType: string
    html?: string
    mentionedUsers?: string[]
    messageID: string
    messageText: string
  }) {
    const body = {
      data: {
        message: {
          attachments: attachments,
          customHTML: html,
          html: html,
          id: messageID,
          mentioned_users: mentionedUsers,
          text: messageText
        }
      },
      headers: {
        'stream-auth-type': 'jwt'
      },
      tokenType: 'User-Chat-Token' as TokenType,
      url: `https://chat.stream-io-api.com/channels/${channelType}/${channelID}/message?user_id=${this.loggedInUserID}&api_key=${config.app.STREAM_APP_KEY}`
    }

    try {
      await this.post(body)
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }
}
