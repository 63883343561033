import React, { lazy, Suspense } from 'react'

import { BookingPageLayout } from '../../booking/booking-layout'
import { WorkspacePageLayout } from '../../booking/workspace-page/workspace-page-layout'
import { lazyRetry } from '../lazy-retry'
import { ROUTES } from '../routes-list'

const WorkspacePostSearchPage = lazy(() =>
  lazyRetry(() => import('../../booking/search-workspace-post-page'), 'WorkspacePostSearchPage')
)

const PublicPlatformEventLogPage = lazy(() =>
  lazyRetry(() => import('../../settings/logging/public-log-page'), 'PublicPlatformEventLogPage')
)

const PublicSharedTrackingPage = lazy(() =>
  lazyRetry(() => import('../../shipment/tracking/public-tracking-page'), 'PublicSharedTrackingPage')
)

const PublicTrackingMapInlineFrame = lazy(() =>
  lazyRetry(() => import('../../shipment/tracking/inline-frame'), 'PublicTrackingMapInlineFrame')
)

const PublicWorkspacePage = lazy(() => lazyRetry(() => import('../../booking/workspace-page'), 'PublicWorkspacePosts'))

const SinglePostPage = lazy(() => lazyRetry(() => import('../../booking/single-post-page'), 'Post'))

export const publicRoutes = () => {
  return [
    {
      element: (
        <Suspense>
          <WorkspacePostSearchPage />
        </Suspense>
      ),
      path: ':handle/search'
    },
    {
      element: (
        <Suspense>
          <PublicPlatformEventLogPage />
        </Suspense>
      ),
      path: 'share/log/:requestID'
    },
    {
      element: (
        <Suspense>
          <PublicSharedTrackingPage />
        </Suspense>
      ),
      path: '/:handle/share/shipment/tracking/:shipmentID'
    },
    {
      children: [
        {
          element: (
            <Suspense>
              <SinglePostPage />
            </Suspense>
          ),
          path: `/:handle/${ROUTES.POSTS}/:post_id`
        },
        {
          element: (
            <Suspense>
              <SinglePostPage />
            </Suspense>
          ),
          path: `/:handle/:post_id`
        }
      ],
      element: <BookingPageLayout />
    },
    {
      children: [
        {
          element: (
            <Suspense>
              <PublicWorkspacePage />
            </Suspense>
          ),
          path: `/:handle`
        }
      ],
      element: <WorkspacePageLayout />
    }
  ]
}

export const publicRoutesForInlineFrame = () => {
  return [
    {
      element: <PublicTrackingMapInlineFrame />,
      path: 'share/shipment/tracking/map/:shipmentID'
    }
  ]
}
