// External Imports
import 'cross-fetch/polyfill'

// External Imports
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink } from '@apollo/client'
import * as Sentry from '@sentry/react'
import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import config, { Config } from './_server/config'
// Component Imports
import ConfigContext from './_server/config-context'
import { cache } from './_shared/infra/cache'
import errorLink from './_shared/infra/error-link'
import { ColorModeProvider } from './_utils/color-mode-context'
import { isElectron } from './_utils/electron'
import { App, Providers } from './app'
import { tokenService } from './auth/data'
import { UsersWorkspace } from './auth/data/auth-types'
import { MY_WORKSPACES } from './auth/data/queries-mutations'
import { AuthProvider } from './auth/data/use-auth'

// We are only enabling sentry in production environment
const enableSentry =
  config.app.ENV_STAGE === 'prod' &&
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '127.0.0.1'

Sentry.init({
  dsn: 'https://1ab8186f407e1df505cbf79415b594be@o418053.ingest.sentry.io/4505960235663360',
  enabled: enableSentry,
  environment: config.app.ENV_STAGE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      /* cspell:disable-next-line */
      tracePropagationTargets: ['localhost', /^https:\/\/chaineapp\.com/]
    }),
    new Sentry.Replay({
      blockAllMedia: false,
      maskAllText: false
    })
  ],

  replaysOnErrorSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  /* cspell:disable-next-line */
  tracePropagationTargets: ['localhost', /^https:\/\/chaineapp\.com/]
})

declare global {
  interface Window {
    __APOLLO_STATE__?: string
    __CONFIG__?: Config
  }
}

//eslint-disable-next-line
const basename = config.app.URL.match(/^(?:https?:\/\/)?[^\/]+(\/?.+)?$/i)?.[1]

delete window.__CONFIG__
delete window.__APOLLO_STATE__

/**
 * Set router based on if it is electron or browser
 * Electron uses hash router, browser uses browser router
 */
const Router = isElectron() ? HashRouter : BrowserRouter

const httpLink = new HttpLink({ uri: config.app.GRAPHQL_URL })
const authMiddleware = new ApolloLink((operation, forward) => {
  // Add the authorization to the headers

  const customHeaders = Object.prototype.hasOwnProperty.call(operation.getContext(), 'headers')
    ? operation.getContext().headers
    : {}

  let header: { authorization: string; handle?: string; 'ws-id'?: string } = {
    authorization: tokenService.getToken('Access-Token') || ''
  }

  if (customHeaders) {
    header = {
      ...customHeaders,
      ...header
    }
  }

  let isWorkspaceSelected = false
  let workspaceID = ''
  const data = client.readQuery({
    query: MY_WORKSPACES
  })

  const urlsegments = location.pathname.split('/')
  data?.myWorkspaces?.map(({ workspace }: UsersWorkspace) => {
    if (urlsegments.includes(workspace.workspacename)) {
      workspaceID = workspace.id
      isWorkspaceSelected = true
    }
  })

  if (isWorkspaceSelected) {
    header = {
      'ws-id': workspaceID,
      ...header
    }
  }

  operation.setContext(() => ({
    headers: header
  }))
  return forward(operation)
})

const client = new ApolloClient({
  cache,
  connectToDevTools: true,
  link: from([errorLink, authMiddleware, httpLink])
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ConfigContext.Provider value={config}>
    <ApolloProvider client={client}>
      <IntercomProvider appId={config.app.INTERCOM_APP_ID} autoBoot={false}>
        <Router basename={basename}>
          <AuthProvider>
            <ColorModeProvider>
              <Providers>
                <App />
              </Providers>
            </ColorModeProvider>
          </AuthProvider>
        </Router>
      </IntercomProvider>
    </ApolloProvider>
  </ConfigContext.Provider>
)
