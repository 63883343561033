import { useMutation } from '@apollo/client'
import { Button, Flex, FormLabel, KeychaineInput as Input, ModalBody, ModalHeaderLayout, Text } from '@chaine/keychaine'
import React, { memo, useCallback, useEffect, useState } from 'react'

import { authService } from '../../../auth/data'
import { VERIFY_CODE_FOR_WORKSPACE_MEMBER } from '../../layouts/application/header/data'

interface IVerifyCodeForWorkspaceMember {
  constraintWorkspaceID: string
  isResending: boolean
  onClose: () => void
  resendCode: () => void
  selectedEmailOrPhone: string | null
}

/**
 * VerifyCodeForWorkspaceMember verifies the verification code for a workspace member
 */
export const VerifyCodeForWorkspaceMember = memo((props: IVerifyCodeForWorkspaceMember) => {
  const { constraintWorkspaceID, isResending, onClose, resendCode, selectedEmailOrPhone } = props
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [verificationErrorMessage, setVerificationErrorMessage] = useState('')
  const { refetchWorkspaces } = authService.getWorkspacesByUser()

  const codeVerificationRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (null !== codeVerificationRef.current) codeVerificationRef.current.focus()
  }, [])

  const [verifyCodeForWorkspaceMember, { loading }] = useMutation(VERIFY_CODE_FOR_WORKSPACE_MEMBER, {
    onCompleted: () => {
      refetchWorkspaces()
      onClose()
    },
    onError: (error) => {
      setVerificationErrorMessage(error.message || 'Something went wrong.')
    }
  })

  const handleButtonClick = useCallback(() => {
    setVerificationErrorMessage('')
    const input = {
      code: verificationCode,
      workspaceID: constraintWorkspaceID
    }
    verifyCodeForWorkspaceMember({ variables: input })
  }, [verificationCode, constraintWorkspaceID, verifyCodeForWorkspaceMember])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setVerificationCode(value)
    },
    [setVerificationCode]
  )

  return (
    <>
      <ModalHeaderLayout title="Enter verification code" />
      <ModalBody>
        <FormLabel mb={4} mt={1}>
          {`Please enter the 6 digit verification code that was sent to `}
          <Text as="span" fontWeight="semibold">
            {selectedEmailOrPhone}
          </Text>
          {`.`}
        </FormLabel>
        <Flex direction="column" gap={4} mt={4} justifyContent="center" alignItems="center">
          <Input
            ref={codeVerificationRef}
            placeholder="Please enter your verification code"
            name="otp"
            onChange={handleChange}
          />
          {verificationErrorMessage && <Text color="red.500">{verificationErrorMessage}</Text>}
          <Button
            w="full"
            variant="primary"
            onClick={handleButtonClick}
            isLoading={loading}
            isDisabled={verificationCode.length !== 6}
          >
            Submit
          </Button>
          <Button variant="unstyled" onClick={resendCode} fontWeight="semibold">
            {isResending ? 'Sending...' : 'Resend code'}
          </Button>
        </Flex>
      </ModalBody>
    </>
  )
})

VerifyCodeForWorkspaceMember.displayName = 'VerifyCodeForWorkspaceMember'
