import { InMemoryCache } from '@apollo/client'

declare global {
  interface Window {
    __APOLLO_STATE__?: string
  }
}

const apolloState = window.__APOLLO_STATE__ || JSON.stringify({})

/**
 * for getting detailed insights on caching, follow {@link https://www.apollographql.com/docs/react/caching/cache-configuration/}
 */

export const cache = new InMemoryCache({
  typePolicies: {
    //This is a type policy for the CarrierSuggestion type, which is used to store each carrier suggestion in the cache.
    // Type policy for CarrierSuggestion: uses carrierWorkspaceID and postID as keys, or just carrierWorkspaceID if postID is absent.
    CarrierSuggestion: {
      keyFields: (obj) => {
        const { postID } = obj
        return postID ? ['carrierWorkspaceID', 'postID'] : ['carrierWorkspaceID']
      }
    },
    Query: {
      fields: {
        myShipments: {
          merge(_, incoming) {
            return incoming
          }
        },
        postOffers: {
          merge(_, incoming) {
            return incoming
          }
        },
        userPosts: {
          merge(_, incoming) {
            return incoming
          }
        },
        workspaceShipments: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    Shipment: {
      fields: {
        dispatchers: {
          merge(_, incoming) {
            return incoming
          }
        },
        drivers: {
          merge(_, incoming) {
            return incoming
          }
        },
        teamMembers: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },

    ShipmentUser: {
      keyFields: (obj) => {
        // If workspace exists, use user.id and workspace.id for identification
        if (obj.workspace) {
          return ['user', ['id'], 'workspace', ['id']]
        }
        // If workspace doesn't exist, only use user.id for identification
        return ['user', ['id']]
      }
    },
    User: {
      fields: {
        primaryMembership: {
          merge(existing, incoming) {
            // check if the existing primaryMembership object and the incoming primaryMembership object have the same workspaceID and userID
            if (
              existing &&
              incoming &&
              existing.workspaceID === incoming.workspaceID &&
              existing.userID === incoming.userID
            ) {
              // merge the two objects
              return { ...existing, ...incoming }
            }
            // return the incoming object if it doesn't match
            return incoming
          }
        }
      }
    }
  }
}).restore(JSON.parse(apolloState))
