import { Box, CheckIcon, CloseButton, Flex, Icon, Text, useColorMode } from '@chaine/keychaine'
import React from 'react'

type TContent = {
  isDone: boolean
  label: string
}

type TAssessmentCardProps = {
  contents: TContent[]
}

/**
 * It displays basic company details - can be
 * inspected or not, insurance is active or not, etc.
 */
export function AssessmentCard(props: TAssessmentCardProps) {
  const { colorMode } = useColorMode()
  return (
    <Box>
      <Text fontWeight="600" fontSize={16}>
        Assessment
      </Text>
      <Box borderRadius={8} marginTop={4} padding={4} backgroundColor={colorMode === 'dark' ? 'gray.800' : 'gray.50'}>
        <Flex gap="3" wrap="wrap">
          {props.contents.map((ele, id) => (
            <Flex alignItems="center" gap={2} key={`assessment-` + id}>
              {ele.isDone ? (
                <Icon as={CheckIcon} color="green.300" fontSize={12} />
              ) : (
                <Icon as={CloseButton} color="red.500" fontSize={11} />
              )}
              <Text fontSize={14} color={colorMode === 'dark' ? 'gray.300' : 'gray.600'}>
                {ele.label}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
