import React from 'react'
import { RouteObject } from 'react-router-dom'

import CompanyPage from '../../company'
import { RequireAuth } from '../hoc'
import { ROUTES } from '../routes-list'

export const companyRoutes = (): RouteObject[] => {
  return [
    {
      element: (
        <RequireAuth>
          <CompanyPage />
        </RequireAuth>
      ),
      path: `${ROUTES.DOT}/:dotNumber`
    }
  ]
}
