import { Flex, Grid, GridItem } from '@chaine/keychaine'
import React from 'react'

type TCompanyPageLayout = {
  leftChildren: React.ReactNode
  rightChildren: React.ReactNode
}

/**
 * This component will render components in two column grid layout
 */
export default function CompanyPageLayout(props: TCompanyPageLayout) {
  return (
    <Grid templateColumns={{ lg: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} border="yellow" gap={{ lg: 10, sm: 2 }}>
      <GridItem padding={6} paddingLeft={0} paddingRight={0}>
        {props.leftChildren}
      </GridItem>
      <GridItem padding={6} paddingLeft={0} paddingRight={0}>
        {props.rightChildren}
      </GridItem>
    </Grid>
  )
}

type TCompanySectionLayoutProps = {
  children: React.ReactNode
  gap: number
}

export function CompanySectionLayout(props: TCompanySectionLayoutProps) {
  return (
    <Flex direction="column" gap={props.gap}>
      {props.children}
    </Flex>
  )
}
