import { Box, Stack, Text, useColorModeValue as mode } from '@chaine/keychaine'
import * as React from 'react'

/**
 * defaultOptions defined in INavGroupProps are:
 * @param menuHeadingExpanded: menuHeadingExpanded is the parameter that will contain the nav group label when the side menu bar is in expanded form. i.e Workspace,
 * @param menuHeadingContracted: menuHeadingContracted is the parameter that will contain the nav group label when the side menu bar is in contracted form. i.e WS,
 * @param children: children is the parameter that will contain the child component,
 * @param navSize: navSize is the parameter that will indicates whether the side menu bar is expanded or contracted,
 */
interface INavGroupProps {
  children: React.ReactNode
  menuHeadingContracted: string
  menuHeadingExpanded: string
  navSize: string
}

export const NavGroup = (props: INavGroupProps) => {
  const { menuHeadingExpanded, menuHeadingContracted, children, navSize } = props
  const colorExpanded = mode('text.secondary', 'text.tertiary')
  const colorContracted = mode('text.tertiary', 'text.tertiary')
  return (
    <Box>
      {menuHeadingExpanded || menuHeadingContracted ? (
        <Text px={3} fontWeight="bold" color={navSize === 'expanded' ? colorExpanded : colorContracted} mb={4} mt={4}>
          {navSize === 'expanded' ? menuHeadingExpanded : menuHeadingContracted}
        </Text>
      ) : null}
      <Stack spacing="1">{children}</Stack>
    </Box>
  )
}
