import { Button, Icon, lightColors, UnverifiedIcon, useColorModeValue as mode, useDisclosure } from '@chaine/keychaine'
import React, { memo } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '../../../../auth/data/use-auth'
import { useReplaceWorkspacenameInURL } from '../../../components'
import { SetupAndVerifyWorkspaceModal } from '../../../components/setup-workspace'
import { Variant } from '../../../components/setup-workspace/types'

/**
 * CompleteVerificationButton component is used to handle workspace verification
 */
export const CompleteVerificationButton = memo(() => {
  const { selectedWorkspace, setWorkspace, loadingUserDetails } = useAuth()
  const { handle } = useParams()
  const { replaceWorkspacenameInURL } = useReplaceWorkspacenameInURL()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const hoverBgColor = mode('yellow.300', 'yellow.200')
  const buttonBgColor = mode('yellow.200', 'yellow.100')

  const workspace = {
    displayName: selectedWorkspace?.workspace?.displayName || '',
    id: selectedWorkspace?.workspace.id || '',
    logo: selectedWorkspace?.workspace.logo || null,
    workspacename: selectedWorkspace?.workspace.workspacename || ''
  }

  const accessType = selectedWorkspace?.accessType || ''

  if (loadingUserDetails) return null

  return (
    <>
      {!selectedWorkspace?.workspace.isVerified && (
        <Button
          _hover={{ bg: hoverBgColor }}
          _active={{ bg: hoverBgColor }}
          bg={buttonBgColor}
          aria-label="complete verification button"
          color={lightColors.text.primary}
          leftIcon={<Icon as={UnverifiedIcon} color={lightColors.text.primary} />}
          onClick={onOpen}
        >
          {selectedWorkspace && !selectedWorkspace?.workspace.isVerified ? 'Get verified' : 'Complete verification'}
        </Button>
      )}

      {isOpen && (
        <SetupAndVerifyWorkspaceModal
          isOpen={isOpen}
          onClose={onClose}
          variant={Variant.VerifyWorkspace}
          onWorkspaceCreation={setWorkspace}
          workspace={workspace}
          replaceWorkspacenameInURL={!handle ? replaceWorkspacenameInURL : undefined}
          accessType={accessType}
        />
      )}
    </>
  )
})

CompleteVerificationButton.displayName = 'CompleteVerificationButton'
