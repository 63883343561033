import { gql } from '@apollo/client'

import { UserRole, UserStatus } from '../../_shared/types'
import { MembershipAccessType, MembershipStatus } from '../../_shared/types/membership-types'

/**
 * Graphql Mutation - InitiateAuthOrSignup
 * @param emailOrPhone: String value either valid phone number or email
 * @param countryCode: Optional countryCode, if emailOrPhone contains phone number then countryCode value should be send
 */
export const INITIATE_AUTH_OR_SIGNUP = gql`
  mutation InitiateAuthOrSignup($emailOrPhone: String!, $countryCode: String, $createdVia: CreatedVia!) {
    initiateAuthOrSignup(input: { emailOrPhone: $emailOrPhone, countryCode: $countryCode, createdVia: $createdVia }) {
      userID
      session
      emailOrPhone
    }
  }
`

/**
 * Graphql Mutation - VerifyCode
 * @param userID: String value which client getting from InitiateAuthOrSignup step
 * @param code: a verification code, which user got on their phone number or email
 * @param session: a session string which client getting from InitiateAuthOrSignup step
 */
export const VERIFY_CODE = gql`
  mutation VerifyCode($userID: String!, $code: String!, $session: String!) {
    verifyCode(input: { userID: $userID, code: $code, session: $session }) {
      accessToken
      refreshToken
      status
    }
  }
`

/**
 * Graphql Mutation - To revoke user token
 * @param refreshToken: String value which will get from token service
 */
export const REVOKE_USER_TOKEN = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(input: { refreshToken: $refreshToken })
  }
`

/**
 * Graphql Query - To get all the active workspaces by the logged-in user
 * @param status: Active enum value
 */
export const MY_WORKSPACES = gql`
  query {
    myWorkspaces(input: { status: Active }) {
      accessType
      role
      status
      isPrimary
      workspace {
        displayName
        dot
        enabledApps {
          appMetadata {
            domainName
            onboardingURL
          }
          appType
        }
        enterpriseWorkspaceID
        id
        isEnterpriseWorkspace
        isVerified
        logo
        mcNumber
        shipmentsPageSettings {
          hideNeedsCarrierShipments
        }
        workspacename
        workspaceType
        workspaceVerificationType
      }
    }
  }
`
export enum WorkspaceVerificationType {
  Basic = 'Basic',
  Trusted = 'Trusted'
}
/**
 * The return type for the "me" graphql query
 */
export interface MeReturnType {
  colorModePreference?: string

  countryCode?: string | null
  email?: string

  /**
   * The user's UUID
   */
  id: string
  isEmailVerified?: boolean
  isPhoneVerified?: boolean
  isSuper: boolean | null
  /**
   * The name of the user
   */
  name: string | null
  phone?: string
  /**
   * The user's photo
   */
  photo: string | null
  /**
   * The user's primary membership
   */
  primaryMembership?: {
    /**
     * The user's membership access type of type {@link MembershipAccessType}
     */
    accessType: MembershipAccessType
    /**
     * The user's role at their primary workspace of type {@link UserRole}
     */
    role: UserRole
    status: MembershipStatus
    workspace: {
      /**
       * The display name of the workspace
       */
      displayName: string
      /**
       * DOT of the company
       */
      dot: string
      /**
       * The workspaceID
       */
      id: string
      /**
       * Boolean value that indicates if a workspace has gone through basic FMSCA verification
       */
      isVerified: boolean
      /**
       * The logo of the workspace used in Avatar
       */
      logo: string
      /**
       * MC number of the company
       */
      mcNumber: string

      /**
       * The type of verification {@link WorkspaceVerificationType}
       */
      workspaceVerificationType: WorkspaceVerificationType | null

      /**
       * The unique workspacename (analogous to username for a user)
       */
      workspacename: string
    }
  }
  role: UserRole | null
  shipmentsPageSettings: {
    hideNeedsCarrierShipments: boolean
  }
  status: UserStatus
  userPage: {
    bio: string
    freightPreferences: string[]
  }
  /**
   * The user's unique username
   */
  username: string
}

/**
 * Graphql Query - To get the logged-in user's details
 */
export const MY_DETAILS = gql`
  query {
    me {
      name
      username
      id
      photo
      status
      isSuper
      role
      email
      phone
      isEmailVerified
      isPhoneVerified
      countryCode
      colorModePreference
      userPage {
        bio
        freightPreferences
      }
      primaryMembership {
        role
        status
        accessType
        workspace {
          dot
          id
          displayName
          isEnterpriseWorkspace
          isVerified
          logo
          mcNumber
          workspacename
          workspaceVerificationType
        }
      }
      shipmentsPageSettings {
        hideNeedsCarrierShipments
      }
    }
  }
`

/**
 * Graphql Query - To get Chat user token
 */
export const GET_CHAT_USER_TOKEN = gql`
  query {
    chatUserToken: getChatUserToken {
      token
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUserDetails($name: String!, $colorModePreference: String) {
    updateUserDetails(input: { name: $name, colorModePreference: $colorModePreference }) {
      id
    }
  }
`

/**
 * This mutation is used to create username
 * @param username is the username that we generate based on the entered name by user.
 */
export const CREATE_USERNAME = gql`
  mutation CreateUsername($username: String!) {
    createUsername(input: { username: $username }) {
      id
    }
  }
`

/**
 * This query returns constraint if a username exists else null
 * @param constraint is the value to check for set validation, so the value of username
 */
export const VALIDATE_USERNAME_UNIQUENESS = gql`
  query GetConstraint($constraint: String!) {
    getConstraint(input: { constraint: $constraint }) {
      id
    }
  }
`
