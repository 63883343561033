import { useMutation } from '@apollo/client'
import { Button, FormWarning, Radio, RadioGroup, VStack } from '@chaine/keychaine'
import React, { useCallback, useEffect } from 'react'

import { Toast } from '../toast'
import { SEND_VERIFICATION_CODE, SendVerificationCodeInput } from './data/mutations'
import { CompanyVerificationMethodsReturnType } from './data/queries'
import { ErrorModal } from './error-modal'
import { ModalStepProps, ReceiveCodeMethodType, VerificationMethodType } from './types'
import { Wrapper } from './wrapper'

interface SelectVerificationMethodProps extends ModalStepProps {
  /**
   * Response from the initiate workspace verification mutation that returns whether a user is verified for a workspace, or the various verification methods
   */
  allowableVerificationMethods: CompanyVerificationMethodsReturnType | null

  /**
   * A flag to determine whether other verification methods should be hidden from the user.
   */
  hideOtherMethods?: boolean

  /**
   * How the verification code will be sent to the user
   */
  receiveCodeMethod: ReceiveCodeMethodType

  /**
   * Sets the verification strategy (SMS or phone call)
   */
  setReceiveCodeMethod: (strategy: ReceiveCodeMethodType) => void

  /**
   * Set the preferred method for completing verification
   */
  setVerificationMethod: (verificationType: { type: VerificationMethodType; value: string }) => void

  /**
   * The preferred method for completing verification
   */
  verificationMethod: { type: VerificationMethodType; value: string } | null

  /**
   * The UUID of the workspace
   */
  workspaceID: string
}

/**
 * In this step, the user selects how they want to be verified
 */
const UnmemoizedSelectVerificationMethod = (props: SelectVerificationMethodProps) => {
  const {
    hideOtherMethods,
    step,
    previousStepOrSkipStep,
    setVerificationMethod,
    handleNext,
    setReceiveCodeMethod,
    receiveCodeMethod,
    verificationMethod,
    allowableVerificationMethods,
    workspaceID
  } = props
  const { showToast } = Toast()

  // sending code mutation
  const [sendVerificationCode, { loading, error }] = useMutation<
    { sendVerificationCode: { id: string } },
    SendVerificationCodeInput
  >(SEND_VERIFICATION_CODE, {
    onCompleted: () => {
      handleNext()
    },
    onError: (err) => {
      showToast({
        status: 'error',
        title: err.message
      })
    }
  })
  //set the verification method on the first render
  useEffect(() => {
    if (allowableVerificationMethods?.verificationMethods?.[0])
      setVerificationMethod({
        type: allowableVerificationMethods.verificationMethods?.[0]?.type as VerificationMethodType,
        value: allowableVerificationMethods.verificationMethods?.[0]?.value
      })
    const receiveCodeMethodValue =
      allowableVerificationMethods?.verificationMethods?.[0]?.type === VerificationMethodType.Phone
        ? ReceiveCodeMethodType.SMS
        : allowableVerificationMethods?.verificationMethods?.[0]?.type === VerificationMethodType.Email
        ? ReceiveCodeMethodType.Email
        : allowableVerificationMethods?.verificationMethods?.[0]?.type === VerificationMethodType.CompanyEmail
        ? ReceiveCodeMethodType.Email
        : ReceiveCodeMethodType.SMS
    setReceiveCodeMethod(receiveCodeMethodValue)
  }, [allowableVerificationMethods?.verificationMethods, setReceiveCodeMethod, setVerificationMethod])

  const handleClick = useCallback(async () => {
    if (verificationMethod?.type === VerificationMethodType.Email) {
      const input = {
        method: receiveCodeMethod,
        type: verificationMethod?.type
      }
      await sendVerificationCode({
        context: {
          headers: {
            'ws-id': workspaceID || ''
          }
        },
        variables: input
      }) // handleNext is called onCompleted in sendVerification
    } else handleNext()
  }, [receiveCodeMethod, verificationMethod, handleNext, sendVerificationCode, workspaceID])

  if (!allowableVerificationMethods)
    return <ErrorModal step={step} previousStepOrSkipStep={previousStepOrSkipStep} goToStep={3} />

  const handleChange = (event: string) => {
    const verificationType = allowableVerificationMethods?.verificationMethods?.find((method) => method.value === event)
    if (verificationType?.type === VerificationMethodType.Email) setReceiveCodeMethod(ReceiveCodeMethodType.Email)
    if (verificationType?.type === VerificationMethodType.Phone) setReceiveCodeMethod(ReceiveCodeMethodType.SMS)
    if (verificationType?.type === VerificationMethodType.CompanyEmail)
      setReceiveCodeMethod(ReceiveCodeMethodType.Email)

    setVerificationMethod({
      type: (verificationType?.type as VerificationMethodType) || VerificationMethodType.Custom,
      value: event
    })
  }

  function generateVerificationOptionText(params: { type: string; value: string }) {
    switch (params.type) {
      case VerificationMethodType.Email:
        return `Send a code to ${params.value}`
      case VerificationMethodType.Phone:
        return `Call/text a code to ${params.value}`
      case VerificationMethodType.CompanyEmail:
        return `Send a code to an "@${params.value}" email`
      default:
        return params.value
    }
  }

  return (
    <>
      <Wrapper
        title={'How should we verify you?'}
        subtitle={
          'Choose how you would like to receive a temporary 6 digit code.  This information is based on the information on file with the US DOT.'
        }
        activeStep={step}
        backButtonProps={null}
      >
        <RadioGroup
          value={
            verificationMethod?.value
              ? verificationMethod.value
              : allowableVerificationMethods?.verificationMethods?.[0].value
          }
          onChange={handleChange}
          id="verificationMethod"
          name="verificationMethod"
        >
          <VStack width="100%" alignItems="flex-start">
            {allowableVerificationMethods?.verificationMethods?.map((method) => {
              return (
                <Radio key={method.value} value={method.value}>
                  {generateVerificationOptionText(method)}
                </Radio>
              )
            })}
            {!hideOtherMethods && <Radio value="other">I need to be verified a different way 🤔</Radio>}
          </VStack>
        </RadioGroup>
        {error?.message && <FormWarning>{error?.message}</FormWarning>}
        <Button width="100%" onClick={handleClick} mt={4} variant="primary" isLoading={loading}>
          Next
        </Button>
      </Wrapper>
    </>
  )
}

export const SelectVerificationMethod = React.memo(UnmemoizedSelectVerificationMethod)
