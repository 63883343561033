import { IconButton, QuestionIcon, useColorModeValue as mode } from '@chaine/keychaine'
import React from 'react'
import { useIntercom } from 'react-use-intercom'

//Displays the Intercom Button on the Header
export const IntercomButton = ({ shouldShowIntercom }: { shouldShowIntercom: boolean }) => {
  const { show: showIntercom } = useIntercom()
  if (!shouldShowIntercom) {
    return null
  }
  return (
    <IconButton
      aria-label="show-intercom"
      onClick={() => {
        showIntercom()
      }}
      size="xs"
      icon={<QuestionIcon aria-label="question-icon" />}
      border="none"
      bg={mode('gray.900', 'gray.800')}
      color="white"
      outline="none"
      boxShadow="none"
      _hover={{
        bg: 'transparent'
      }}
      _focus={{
        bg: 'transparent'
      }}
      _active={{
        bg: 'transparent'
      }}
    />
  )
}
