import { Box, Divider, Text, useColorMode } from '@chaine/keychaine'
import React from 'react'

type TContent = {
  content: string | React.ReactNode
  heading: string
}

type TDetailsCardProps = {
  contents: TContent[]
}

/**
 * It includes details about telephone, email address,
 * mailing address and physical address
 */
export function DetailsCard(props: TDetailsCardProps) {
  const { colorMode } = useColorMode()
  const contentsLength = props?.contents?.length || 0
  return (
    <Box>
      <Text color="text.primary" fontWeight="600" fontSize={16}>
        Details
      </Text>
      <Box borderRadius={8} marginTop={4} padding={4} backgroundColor={colorMode === 'dark' ? 'gray.800' : 'gray.50'}>
        {props.contents.map((data, idx) => (
          <React.Fragment key={'details-' + idx}>
            <Box marginBottom={4} lineHeight={1.75}>
              <Text fontSize={14} color="text.secondary">
                {data.heading}
              </Text>
              <Text fontSize={14} color="text.primary">
                {data.content}
              </Text>
            </Box>

            {idx < contentsLength - 1 ? <Divider color="#FFEF99" marginTop={4} marginBottom={4} /> : null}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  )
}
