export { Tooltip, useTooltip } from '@chakra-ui/react';
export const tooltipKBDStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'gray.800' : 'white',
        color: colorMode === 'dark' ? 'gray.100' : 'gray.800',
        borderWidth: colorMode === 'dark' ? '0.5px' : '0.5px',
        borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.100',
        borderRadius: 'base',
        fontWeight: 'medium',
        boxShadow: 'md',
        maxW: '320px',
        zIndex: 'tooltip'
    };
};
export const tooltipBaseStyle = (props) => {
    const { colorMode } = props;
    return {
        bg: colorMode === 'dark' ? 'gray.800' : 'white',
        color: colorMode === 'dark' ? 'gray.100' : 'gray.800',
        borderWidth: colorMode === 'dark' ? '0.5px' : '0.5px',
        borderColor: colorMode === 'dark' ? 'gray.700' : 'gray.100',
        borderRadius: 'base',
        padding: 2,
        fontWeight: 'medium',
        boxShadow: 'md',
        maxW: '320px',
        zIndex: 'tooltip'
    };
};
