export const lightColors = {
    black: '#1b1f24',
    white: '#FFFFFF',
    gray: {
        50: '#f6f8fa',
        100: '#eaeef2',
        200: '#d0d7de',
        300: '#afb8c1',
        400: '#8c959f',
        500: '#6e7781',
        600: '#57606A',
        700: '#424A53',
        800: '#32383F',
        900: '#24292F'
    },
    blue: {
        50: '#ddf4ff',
        100: '#b6e3ff',
        200: '#80ccff',
        300: '#54aeff',
        400: '#218bff',
        500: '#0969da',
        600: '#0550ae',
        700: '#033d8b',
        800: '#0a3069',
        900: '#002155'
    },
    green: {
        50: '#dafbe1',
        100: '#aceebb',
        200: '#6fdd8b',
        300: '#4ac26b',
        400: '#2da44e',
        500: '#1a7f37',
        600: '#116329',
        700: '#044f1e',
        800: '#003d16',
        900: '#002d11'
    },
    teal: {
        50: '#F4FDFF',
        100: '#DBF5FF',
        200: '#B8EBFF',
        300: '#97E0FF',
        400: '#78D4FE',
        500: '#5AC8FA',
        600: '#4CB0DD',
        700: '#3F98BF',
        800: '#337F9F',
        900: '#276680'
    },
    yellow: {
        50: '#fff8c5',
        100: '#fae17d',
        200: '#eac54f',
        300: '#d4a72c',
        400: '#bf8700',
        500: '#9a6700',
        600: '#7d4e00',
        700: '#633c01',
        800: '#4d2d00',
        900: '#3b2300'
    },
    orange: {
        50: '#FFF8F0',
        100: '#FFEBCC',
        200: '#FFD699',
        300: '#FFC166',
        400: '#FFAB33',
        500: '#FF9500',
        600: '#DF8300',
        700: '#BF7100',
        800: '#9F5F00',
        900: '#804C00'
    },
    red: {
        50: '#ffebe9',
        100: '#ffcecb',
        200: '#ffaba8',
        300: '#ff8182',
        400: '#fa4549',
        500: '#cf222e',
        600: '#a40e26',
        700: '#82071e',
        800: '#660018',
        900: '#4c0014'
    },
    purple: {
        50: '#fbefff',
        100: '#ecd8ff',
        200: '#d8b9ff',
        300: '#c297ff',
        400: '#a475f9',
        500: '#8250df',
        600: '#6639ba',
        700: '#512a97',
        800: '#3e1f79',
        900: '#2e1461'
    },
    pink: {
        50: '#FFF3F8',
        100: '#FFE0ED',
        200: '#FFC2DA',
        300: '#FFA5C9',
        400: '#FF8AB7',
        500: '#FF70A6',
        600: '#DF6091',
        700: '#BF507C',
        800: '#9F4167',
        900: '#803352'
    },
    text: {
        primary: '#24292F',
        secondary: '#57606A',
        tertiary: '#6E7781'
    },
    border: {
        primary: '#EAEEF2',
        secondary: '#D0D7DE',
        tertiary: '#AFB8C1'
    },
    icon: {
        primary: '#24292F',
        secondary: '#57606A',
        tertiary: '#6E7781'
    },
    grayGradiant: 'linear(gray.100, gray.200)',
    blueGradient: 'linear(blue.400, blue.500)',
    whitefade: {
        10: 'rgba(255, 255, 255, 0.10)',
        15: 'rgba(255, 255, 255, 0.15)',
        30: 'rgba(255, 255, 255, 0.30)',
        70: 'rgba(255, 255, 255, 0.70)'
    },
    grayfade: {
        10: 'rgba(174, 174, 178, 0.10)',
        15: 'rgba(174, 174, 178, 0.15)',
        30: 'rgba(174, 174, 178, 0.30)',
        70: 'rgba(174, 174, 178, 0.70)'
    },
    blackfade: {
        10: 'rgba(30, 30, 30, 0.10)',
        15: 'rgba(30, 30, 30, 0.15)',
        30: 'rgba(30, 30, 30, 0.30)',
        50: 'rgba(30, 30, 30, 0.50)',
        70: 'rgba(30, 30, 30, 0.70)'
    },
    redFade: {
        10: 'rgba(244, 33, 46, 0.1)'
    },
    whiteAlpha: {
        50: 'rgba(255, 255, 255, 0.04)',
        100: 'rgba(255, 255, 255, 0.06)',
        200: 'rgba(255, 255, 255, 0.08)',
        300: 'rgba(255, 255, 255, 0.16)',
        400: 'rgba(255, 255, 255, 0.24)',
        500: 'rgba(255, 255, 255, 0.36)',
        600: 'rgba(255, 255, 255, 0.48)',
        700: 'rgba(255, 255, 255, 0.64)',
        800: 'rgba(255, 255, 255, 0.80)',
        900: 'rgba(255, 255, 255, 0.92)'
    },
    blackAlpha: {
        50: 'rgba(0, 0, 0, 0.04)',
        100: 'rgba(0, 0, 0, 0.06)',
        200: 'rgba(0, 0, 0, 0.08)',
        300: 'rgba(0, 0, 0, 0.16)',
        400: 'rgba(0, 0, 0, 0.24)',
        500: 'rgba(0, 0, 0, 0.36)',
        600: 'rgba(0, 0, 0, 0.48)',
        700: 'rgba(0, 0, 0,  0.64)',
        800: 'rgba(0, 0, 0, 0.80)',
        900: 'rgba(0, 0, 0, 0.92)'
    },
    grayDark: '#2C2C2E',
    bgGray: '#F5F5F5',
    bgBlack: '#0B0913',
    customGray: '#F9F9F9',
    bodyText: '#3D3D3D',
    dark: {
        100: '#343443',
        200: '#272737',
        300: '#1D1D28',
        400: '#1A1924'
    }
};
