import { Box, CheckIcon, Flex, Icon, Text, useColorMode } from '@chaine/keychaine'
import React from 'react'

type TContent = {
  label: string
  value: string
}

type TAuthorityCardProps = {
  contents: TContent[]
}

/**
 * It displays information about company authority and how long
 * the authority will last
 */
export function AuthorityCard(props: TAuthorityCardProps) {
  const { colorMode } = useColorMode()
  return (
    <Box borderBottom={'1px'} borderTop={'1px'} borderColor={colorMode === 'dark' ? 'gray.600' : 'gray.200'}>
      <Box borderRadius="8px" paddingBottom="12px" paddingTop="12px">
        <Flex rowGap={5} columnGap={10} wrap="wrap">
          {props.contents.map((item, id) => (
            <Flex direction="column" gap={2} key={'authority-' + id}>
              <Text fontSize={14} color={colorMode === 'dark' ? 'gray.300' : 'gray.600'} textTransform="uppercase">
                {item.label}
              </Text>
              <Flex alignItems="center" gap={1}>
                <Icon
                  as={CheckIcon}
                  color="white"
                  backgroundColor="green.300"
                  width={4}
                  height={4}
                  borderRadius="50%"
                  padding={0.5}
                />
                <Text fontSize={14} color="text.primary" fontWeight="bold">
                  {item.value}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
