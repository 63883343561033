import { Box, Divider, Flex, ListItem, Text, UnorderedList, useColorMode } from '@chaine/keychaine'
import React from 'react'

type TIntelligenceCard = {
  heading: string
  items: string[]
}

type TIntelligenceCardProps = {
  contents: TIntelligenceCard[]
}

/**
 * This section summarizes the information about FMCSA changes and associations
 * company can have
 */
export function IntelligenceCard(props: TIntelligenceCardProps) {
  const { colorMode } = useColorMode()
  const totalItemsCount = props.contents.length

  return (
    <Box>
      <Text color="yellow.500" fontWeight="600" fontSize={16}>
        Intelligence
      </Text>
      <Box
        border={1}
        borderStyle="solid"
        borderColor={colorMode === 'dark' ? '#564D01' : '#FFEF99'}
        borderRadius={8}
        marginTop={4}
        padding={4}
        backgroundColor={colorMode === 'dark' ? '#302E1C' : '#FFFDF0'}
      >
        {props.contents.map((section, index) => (
          <React.Fragment key={'intelligence-' + index}>
            <Flex key={index} gap={2} direction="column">
              <Text fontSize={14} color="text.secondary">
                {section.heading}
              </Text>
              {section.items.length !== 0 ? (
                <UnorderedList lineHeight={6}>
                  {section.items.map((item, itemId) => (
                    <ListItem color="text.primary" key={itemId}>
                      {item}
                    </ListItem>
                  ))}
                </UnorderedList>
              ) : (
                <Text>This carrier has no changes to their FMCSA Census data</Text>
              )}
            </Flex>
            {index < totalItemsCount - 1 ? <Divider color="#FFEF99" marginTop={4} marginBottom={4} /> : null}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  )
}
