import { Box, Icon, InfoIcon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chaine/keychaine'
import React, { useState } from 'react'

import { OverviewTabPanel } from './overview-tab-panel'

/**
 * This component renders two tabs Overview and Intelligence
 * Overview tab displays summary about modifications, associations and other details like inspections, crashes
 * Intelligence tab displays details of associations and dot numbers
 */

export function CompanyDetailsTabs() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const tabsList = [
    {
      heading: (idx: number) => (
        <Box display="flex" gap={2} alignItems="center">
          <Icon as={InfoIcon} boxSize={4} color={idx === selectedTabIndex ? 'text.primary' : 'text.secondary'} />
          <Text color="text.primary" fontWeight={selectedTabIndex === idx ? 'bold' : ''}>
            Overview
          </Text>
        </Box>
      )
    }
  ]

  return (
    <Tabs
      colorScheme="orange"
      onChange={(index) => {
        setSelectedTabIndex(index)
      }}
    >
      <TabList>
        {tabsList.map(({ heading }, index) => (
          <Tab key={index} p={3}>
            {heading(index)}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel>
          <OverviewTabPanel />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
