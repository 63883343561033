import { defineStyleConfig } from '@chakra-ui/react';
import { blue, bodyText, gray, grayDark, inactive, red, white } from '../../typography/text';
export const Text = defineStyleConfig({
    baseStyle: {
        fontSizes: '14px'
    },
    variants: { bodyText, grayDark, gray, inactive, blue, red, white },
    defaultProps: {
        variant: 'bodyText'
    }
});
