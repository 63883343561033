import { Button, FormLabel, ModalBody, ModalHeaderLayout, Radio, RadioGroup, Text, VStack } from '@chaine/keychaine'
import React, { memo } from 'react'

import { useAuth } from '../../../auth/data/use-auth'
import { IWorkspaceMember } from '../../../settings/members/data/queries'

interface ISelectEmailOrPhone {
  carrierWorkspaceDisplayName: string | null | undefined
  getVerificationCode: () => void
  gettingCode: boolean
  selectedMemberID: string | null
  setSelectedMemberID: (value: string) => void
  workspaceMembers: IWorkspaceMember[]
}

/**
 * SelectEmailOrPhone renders a list of emails or phones and enable the user to select any one of them.
 */
export const SelectEmailOrPhone = memo((props: ISelectEmailOrPhone) => {
  const {
    carrierWorkspaceDisplayName,
    gettingCode,
    getVerificationCode,
    setSelectedMemberID,
    selectedMemberID,
    workspaceMembers
  } = props

  const { userDetails } = useAuth()

  return (
    <>
      <ModalHeaderLayout title={`Request to be added to ${carrierWorkspaceDisplayName}`} />
      <ModalBody>
        <FormLabel mb={4} mt={1} htmlFor="emailOrPhone">
          {`You must be part of ${carrierWorkspaceDisplayName} to bid on this load, but `}
          <Text as="span" fontWeight="semibold">
            {userDetails.email || userDetails.phone}
          </Text>
          {` is not registered for this company. To complete your registration, please send a verification code to
          one of the following emails:`}
        </FormLabel>
        <RadioGroup id="emailOrPhone" name="emailOrPhone" onChange={setSelectedMemberID} value={selectedMemberID || ''}>
          <VStack pl={1} width="100%" alignItems="flex-start" overflowY="auto" maxH="96">
            {workspaceMembers.map((member, index) => {
              return (
                <Radio value={member.user?.id} key={index}>
                  {member?.user?.name && (
                    <>
                      <Text fontWeight="medium" as={'span'}>
                        {`${member.user.name} `}
                      </Text>
                      {member.accessType && (
                        <Text fontWeight="medium" as={'span'}>
                          {`(${member.accessType}) `}
                        </Text>
                      )}

                      {'at '}
                    </>
                  )}
                  {`${member.user?.email}`}
                </Radio>
              )
            })}
          </VStack>
        </RadioGroup>
        <Button
          w="full"
          variant="primary"
          mt={4}
          isDisabled={!selectedMemberID}
          onClick={getVerificationCode}
          isLoading={gettingCode}
        >
          Submit
        </Button>
      </ModalBody>
    </>
  )
})

SelectEmailOrPhone.displayName = 'SelectEmailOrPhone'
