export const darkColors = {
    black: '#010409',
    white: '#FFFFFF',
    gray: {
        50: '#f0f6fc',
        100: '#c9d1d9',
        200: '#b1bac4',
        300: '#8b949e',
        400: '#6e7681',
        500: '#484f58',
        600: '#30363d',
        700: '#21262D',
        800: '#161B22',
        900: '#0D1117'
    },
    blue: {
        50: '#cae8ff',
        100: '#a5d6ff',
        200: '#79c0ff',
        300: '#58a6ff',
        400: '#388bfd',
        500: '#1f6feb',
        600: '#1158c7',
        700: '#0d419d',
        800: '#0c2d6b',
        900: '#051d4d'
    },
    green: {
        50: '#aff5b4',
        100: '#7ee787',
        200: '#56d364',
        300: '#3fb950',
        400: '#2ea043',
        500: '#238636',
        600: '#196c2e',
        700: '#0f5323',
        800: '#033a16',
        900: '#04260f'
    },
    teal: {
        50: '#F4FDFF',
        100: '#DBF5FF',
        200: '#B8EBFF',
        300: '#97E0FF',
        400: '#78D4FE',
        500: '#5AC8FA',
        600: '#4CB0DD',
        700: '#3F98BF',
        800: '#337F9F',
        900: '#276680'
    },
    yellow: {
        50: '#f8e3a1',
        100: '#f2cc60',
        200: '#e3b341',
        300: '#d29922',
        400: '#bb8009',
        500: '#9e6a03',
        600: '#845306',
        700: '#693e00',
        800: '#4b2900',
        900: '#341a00'
    },
    orange: {
        50: '#ffdfb6',
        100: '#ffc680',
        200: '#ffa657',
        300: '#f0883e',
        400: '#db6d28',
        500: '#bd561d',
        600: '#9b4215',
        700: '#762d0a',
        800: '#5a1e02',
        900: '#3d1300'
    },
    red: {
        50: '#ffdcd7',
        100: '#ffc1ba',
        200: '#ffa198',
        300: '#ff7b72',
        400: '#f85149',
        500: '#da3633',
        600: '#b62324',
        700: '#8e1519',
        800: '#67060c',
        900: '#490202'
    },
    purple: {
        50: '#eddeff',
        100: '#e2c5ff',
        200: '#d2a8ff',
        300: '#BE8FFF',
        400: '#AB7DF8',
        500: '#8957e5',
        600: '#6e40c9',
        700: '#553098',
        800: '#3c1e70',
        900: '#271052'
    },
    pink: {
        50: '#ffdaec',
        100: '#ffbedd',
        200: '#ff9bce',
        300: '#f778ba',
        400: '#db61a2',
        500: '#bf4b8a',
        600: '#9e3670',
        700: '#7d2457',
        800: '#5e103e',
        900: '#42062a'
    },
    text: {
        primary: '#F0F6FC',
        secondary: '#C9D1D9',
        tertiary: '#8B949E'
    },
    border: {
        primary: '#21262D',
        secondary: '#484F58',
        tertiary: '#6E7681'
    },
    icon: {
        primary: '#F0F6FC',
        secondary: '#C9D1D9',
        tertiary: '#8B949E'
    },
    grayGradiant: 'none',
    blueGradient: 'linear(blue.600, blue.700)',
    whitefade: {
        10: 'rgba(255, 255, 255, 0.10)',
        15: 'rgba(255, 255, 255, 0.15)',
        30: 'rgba(255, 255, 255, 0.30)',
        70: 'rgba(255, 255, 255, 0.70)'
    },
    grayfade: {
        10: 'rgba(174, 174, 178, 0.10)',
        15: 'rgba(174, 174, 178, 0.15)',
        30: 'rgba(174, 174, 178, 0.30)',
        70: 'rgba(174, 174, 178, 0.70)'
    },
    blackfade: {
        10: 'rgba(30, 30, 30, 0.10)',
        15: 'rgba(30, 30, 30, 0.15)',
        30: 'rgba(30, 30, 30, 0.30)',
        50: 'rgba(30, 30, 30, 0.50)',
        70: 'rgba(30, 30, 30, 0.70)'
    },
    redFade: {
        10: 'rgba(244, 33, 46, 0.1)'
    },
    whiteAlpha: {
        50: 'rgba(255, 255, 255, 0.04)',
        100: 'rgba(255, 255, 255, 0.06)',
        200: 'rgba(255, 255, 255, 0.08)',
        300: 'rgba(255, 255, 255, 0.16)',
        400: 'rgba(255, 255, 255, 0.24)',
        500: 'rgba(255, 255, 255, 0.36)',
        600: 'rgba(255, 255, 255, 0.48)',
        700: 'rgba(255, 255, 255, 0.64)',
        800: 'rgba(255, 255, 255, 0.80)',
        900: 'rgba(255, 255, 255, 0.92)'
    },
    blackAlpha: {
        50: 'rgba(0, 0, 0, 0.04)',
        100: 'rgba(0, 0, 0, 0.06)',
        200: 'rgba(0, 0, 0, 0.08)',
        300: 'rgba(0, 0, 0, 0.16)',
        400: 'rgba(0, 0, 0, 0.24)',
        500: 'rgba(0, 0, 0, 0.36)',
        600: 'rgba(0, 0, 0, 0.48)',
        700: 'rgba(0, 0, 0,  0.64)',
        800: 'rgba(0, 0, 0, 0.80)',
        900: 'rgba(0, 0, 0, 0.92)'
    },
    grayDark: '#2C2C2E',
    bgGray: '#F5F5F5',
    bgBlack: '#0B0913',
    customGray: '#F9F9F9',
    bodyText: '#3D3D3D',
    dark: {
        100: '#343443',
        200: '#272737',
        300: '#1D1D28',
        400: '#1A1924'
    }
};
