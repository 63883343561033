// External Imports
import 'cross-fetch/polyfill'

import {
  ColorModeScript,
  Confetti as KeychaineConfetti,
  KeychaineProvider,
  theme,
  useBreakpointValue,
  useColorMode
} from '@chaine/keychaine'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import config from '../src/_server/config'
import { useRenderRoutes } from './_routes'
/** Import components */
import { DocumentTitle } from './_shared/components'
import constant from './_shared/constants/message'
import SafeAreaInsetsProvider from './_shared/layouts/safe-area-context'
import { AnalyticsProvider } from './_utils/analytics-context'
import { useColorModeContext } from './_utils/color-mode-context'
import { useAuth } from './auth/data/use-auth'
import { ChatProvider } from './chat/context/chat-context'

declare global {
  interface Window {
    google?: unknown
  }
}

export function Providers({ children }: { children: React.ReactNode }) {
  //use the current theme object, based on the current color mode in the color mode context
  const { currentThemeObject } = useColorModeContext()

  //overrides the color theme from keychaine, based on the current color mode
  const calculatedTheme = { ...theme, colors: { ...currentThemeObject } }

  return (
    <SafeAreaInsetsProvider>
      <AnalyticsProvider>
        <ChatProvider>
          <KeychaineProvider theme={calculatedTheme}>
            <ColorModeScript />
            {children}
          </KeychaineProvider>
        </ChatProvider>
      </AnalyticsProvider>
    </SafeAreaInsetsProvider>
  )
}

export function App() {
  const [confetti, setConfetti] = useState(false)
  const [title, setTitle] = useState('Chaine')
  const [loaded, setLoaded] = useState(false)
  const location = useLocation()
  const { selectedWorkspace } = useAuth()
  const workspaceLogo = selectedWorkspace?.workspace?.logo

  DocumentTitle(title)

  //Note: at this point in time we must toggle both our color theme hook as well as chakra's to keep the two in sync, due to our reliance on chakra's `useColorModeValue` hook.  Later we can remove this dependency and use our own hook (`useMode`) to toggle the color mode.  This will be needed if we add any additional themes beyond light and dark.

  //import chakra's color more toggle
  const { colorMode: chakraColorMode, toggleColorMode } = useColorMode()
  //import our color mode toggle
  const { colorMode, changeColorMode } = useColorModeContext()

  //this use effect is here to sync the color mode between the two color modes, when a user is logged out and they have a different color mode selected, when they log in, their preferred color mode will be synced
  useEffect(() => {
    if (chakraColorMode !== colorMode) {
      toggleColorMode()
    }
  }, [colorMode, chakraColorMode, toggleColorMode])

  const setDocumentTitleIcon = () => {
    // Create a new link element
    const link = document.createElement('link')
    const chaineIconLink = document.querySelector("link[rel*='icon']")

    // Set the properties of link element
    link.id = 'favicon'
    link.rel = 'icon'
    link.type = 'image/x-icon' // or 'image/png' if using .png images
    link.href = workspaceLogo || ''

    // Replace or append the new favicon to the document
    if (chaineIconLink) {
      document.head.removeChild(chaineIconLink)
    }
    document.head.appendChild(link)
  }

  useEffect(() => {
    const pathname = location.pathname.replace(/[^\w\s]/gi, '')
    const docTitle = constant.PAGE_TITLE.find((item) => {
      const key = item.key.replace(/[^\w\s]/gi, '')
      if (key === pathname || pathname.includes(key)) {
        return item
      } else return false
    }) || { key: 'Default', title: 'Chaine' }
    if (docTitle?.title) setTitle(docTitle?.title)
  }, [location.pathname])

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  //Show the intercom button only when on PROD env and not on mobile
  const shouldShowIntercom = !isMobile && import.meta.env.PROD
  const { boot, shutdown } = useIntercom()

  useEffect(() => {
    if (shouldShowIntercom) {
      boot({
        actionColor: 'blue',
        hideDefaultLauncher: true
      })
    } else {
      shutdown()
    }
  }, [boot, shutdown, shouldShowIntercom])

  useEffect(() => {
    setLoaded(true)
    setDocumentTitleIcon()

    //Load google script
    const loadGoogleScript = () => {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${config.app.GOOGLE_API_KEY}&libraries=places`
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    }
    if (!window.google) loadGoogleScript()
  }, [])

  /**
   * Refer to react route v6 implementation
   * @see {@link https://reactrouter.com/docs/en/v6/getting-started/concepts#index-routes article} for more information
   */
  return (
    <>
      {confetti && loaded && <KeychaineConfetti variant={'apple'} onComplete={() => setConfetti(false)} />}
      <React.Suspense>{useRenderRoutes(setConfetti, changeColorMode)}</React.Suspense>
    </>
  )
}
